import React from 'react';
import { Box, Heading, Text, VStack, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/interface';

const BankDetails: React.FC = () => {
  const language = useSelector((state: RootState) => state.language.language);
  return (
    <VStack
      spacing={4}
      mt={8}
      p={4}
      border="1px solid"
      borderColor={useColorMode().colorMode === 'light' ? '#345430' : 'white'}
      borderRadius="md"
      width="100%"
    >
      <Heading size="md">Bank Account Details</Heading>
      <Text>NAME: IFANIWURA LIMITED</Text>
      <Box>
        <Text>ACCOUNT NOS:</Text>
        <Text>0884935813 (NGN)</Text>
        <Text>0884935820 (USD)</Text>
      </Box>

      <Text>GTBANK</Text>

              <Box
                p={4}
                border="1px solid #345430"
                borderRadius="md"
                mt={4}
                width="100%"
                gap={10}
                fontFamily="Inter"
              >
                <Text 
                  fontSize="16px"
                  mb={5}
                >
                  {language === 'en' ? 'Amount: Your preferred amount' : language === 'es' ? 'Cantidad: La cantidad que prefieras' : 'Owo: ọmọ rẹ ti pọn'},
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? 'Account Number (NGN): 0884935813' : language === 'es' ? 'Número de cuenta (NGN): 0884935813' : 'Number Ileyi fi owo pa mo(NGN): 0884935813 '}
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? 'Account Number (USD): 0884935820' : language === 'es' ? 'Número de cuenta (USD): 0884935820' : 'Nọmba aṣọ (USD): 0884935820 '}
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? 'Account Name:  IFANIWURA LIMITED' : language === 'es' ? 'Nombre de la cuenta: IFANIWURA LIMITED' : ' Oruko Account wa:  IFANIWURA LIMITED'}
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? 'Bank Name: Guranty Trust Bank' : language === 'es' ? 'Nombre del Banco: Guranty Trust Bank' : ' Ileyi fi owo pa mo: Guranty Trust Bank'}
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? "Beneiciary's Bank Swift Code: GTBINGLA" : language === 'es' ? "Beneiciary's Bank Swift Code: GTBINGLA" : "Beneiciary's Bank Swift Code: GTBINGLA" }
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? "Beneiciary's Bank Branch Address: Lekki Peninsula Epe Expy Chevron Branch" : language === 'es' ? "Beneiciary's Bank Branch Address: Lekki Peninsula Epe Expy Chevron Branch" 
                  : "Beneiciary's Bank Branch Address: Lekki Peninsula Epe Expy Chevron Branch"}
                </Text>
                <Text fontSize="16px" mb={5}>
                  {language === 'en' ? 'For: Ewe Store' : language === 'es' ? 'Para: Ewe Store' : 'Fún: Ewe Store'}
                </Text>
                <Text fontSize="16px" mt={4}>
                  {language === 'en'
                    ? 'Proceed to your bank app to complete this transfer.'
                    : language === 'es' ? 'Proceda a su aplicación bancaria para completar esta transferencia.' : 'Lọ sile si ọna agbaniro fun rẹ lati ṣe ọna iparun iwekọ yi.'}
                </Text>
              </Box>
    </VStack>
  );
};

export default BankDetails;
