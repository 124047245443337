import { useEffect, useRef, useState } from "react";
import {
  Select,
  Button,
  Textarea,
  Input,
  Flex,
  Image,
  Heading,
  VStack,
  useColorMode,
  Box,
  Text,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller, useFieldArray } from "react-hook-form";
import { ToastNotification } from "../components";
import { FilterOption, RootState, User } from "../types/interface";
import { filterOptions } from "../data/filterType";
import { MdDelete } from "react-icons/md";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { selectCurrentUser, selectAccessToken } from "../slice/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPostQuery, useUpdatePostMutation } from '../api/postApi';
import axios from "axios";

interface FormData {
  location: { latitude: number; longitude: number; };
  lang: string;
  name: string;
  description: string;
  uses: string;
  otherNames: { value: string }[];
  images: (string | File)[];
  type: string;
}

// const MAX_COUNT = 4;

const EditPost = () => {
  const { postId , userId} = useParams(); // Get the post ID from URL params
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });
  const accessToken = useSelector(selectAccessToken); 
  const [backendImages, setBackendImages] = useState<string[]>([]); // Existing images
  const [previewImages, setPreviewImages] = useState<string[]>([]); // Previews for new files
  

  const navigate = useNavigate(); // Add useNavigate hook
  // useEffect(() => {
  //   console.log("userId:", userId, "postId:", postId); // Debugging
  // }, [userId, postId]);

    // Fetch post data using RTK Query
    const { data: postData, isLoading: isLoadingPost } = useGetPostQuery(postId!);
    const [updatePost] = useUpdatePostMutation();
  
    // console.log('Post Data:', postData);

  const { handleSubmit, 
    control, 
    register, 
    formState: { isSubmitting, errors },
    reset, 
    setValue } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      uses: '',
      type: '',
      otherNames: [],
      images: [],
    },
    criteriaMode: 'all',
    mode: 'onChange', // Trigger validation only for changed fields
  });
  
 // Set form defaults when post data is loaded
 useEffect(() => {
  if (postData) {
    setValue('name', postData?.data?.name);
    setValue('description', postData?.data?.description);
    setValue('uses', postData?.data?.uses);
    setValue('type', postData?.data?.type);
    setValue('otherNames', postData?.data?.othernames?.map((name: string) => ({ value: name })));
    setBackendImages(postData?.data?.images || []);
    setValue('images', postData?.data?.images || []);
  }
}, [postData, setValue]);



const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;
  if (files) {
    const filesArray = Array.from(files);

    const uploadedUrls: string[] = [];
    for (const file of filesArray) {
      if (file.type.startsWith('image/')) {
        const formData = new FormData();
        formData.append('files', file);


      setLoading(true);
      setToastConfig({ description: 'Uploading images...', status: 'info' });
      setShowToast(true);

        try {
          const response = await axios.post(
            `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.data?.data) {
            uploadedUrls.push(response.data.data); // Add uploaded URL
          }
        } catch (error) {
          console.error('Image upload error:', error);
          setToastConfig({ description: 'Upload failed.', status: 'error' });
          setShowToast(true);
        }
      }
    }
    if (uploadedUrls.length > 0) {
    // Merge newly uploaded URLs with existing ones
    const updatedImages = [...backendImages, ...previewImages, ...uploadedUrls];
    setPreviewImages((prev) => [...prev, ...uploadedUrls]);
    setValue('images', updatedImages);
    setToastConfig({ description: 'Images uploaded successfully.', status: 'success' });
    setShowToast(true);
  }
  setLoading(false);
  }
};


  const { fields, append, remove } = useFieldArray({
    name: 'otherNames',
    control,
  });

  const filter: FilterOption[] = filterOptions;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    // console.log("Submit data: ", data);
  
    try {
      setLoading(true);
  
      // Prepare the payload directly
      const payload = {
        name: data.name,
        description: data.description,
        uses: data.uses,
        type: data.type,
        location: data.location?.latitude && data.location?.longitude ? JSON.stringify(data.location) : undefined,
        othernames: data.otherNames?.map(({ value }) => value) || [],
        images: data?.images,
      };
  
      // Update post using mutation
      const response = await updatePost({
        userId: userId,
        id: postId,
        updatedPost: payload,
      });
  
      // Log the response to the console
      // console.log('Update Response:', response);
  
      if ('data' in response) {
        setToastConfig({ description: 'Post updated successfully!', status: 'success' });
        setShowToast(true);
  
        // Redirect or reset the form if needed
        reset();
        navigate(`/user/${currentUser.id}/posts/${postId}`);
      } else if ('error' in response) {
        throw new Error('Failed to update the post.');
      }
    } catch (error: any) {
      console.error('Error:', error.message || 'An error occurred');
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click event of the file input element
    }
  };

  if (isLoadingPost) {
    return (
      <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <Center h="100vh" flexDirection="column">
          <Spinner size="lg" color="teal.500" mb={4} />
          <Text fontSize="xl" color="teal.600">Loading...</Text>
        </Center>
      </Box>
    );
  }

 
 // Remove images (either from backend or new uploads)
 const handleImageRemove = (index: number, fromBackend: boolean) => {
  if (fromBackend) {
    const updatedBackendImages = [...backendImages];
    updatedBackendImages.splice(index, 1);
    setBackendImages(updatedBackendImages);
    setValue('images', [...updatedBackendImages, ...previewImages]);
  } else {
    const updatedPreviews = [...previewImages];
    updatedPreviews.splice(index, 1);
    setPreviewImages(updatedPreviews);
    setValue('images', [...backendImages, ...updatedPreviews]);
  }
};
  
const resetFileState = () => {
  setPreviewImages([]);
  setValue('images', backendImages);
};
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', color: colorMode === 'light' ? '#345430' : 'white' }}
    >
      <Helmet>
        <title>Edit {postData?.data?.name || 'Post'}</title>
        <meta charSet="utf-8" />
      </Helmet>
      <motion.div
        style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', paddingBottom: "20px" }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
      >
        <Box fontFamily="Inter" paddingTop={20} marginRight={{ base: "15", md: "20" }} marginLeft={{ base: "15", md: "20" }} pb={20}>
        <Heading fontFamily="Inter" as="h6" fontSize={{ base: '20px', md: "30px"}} 
        textAlign="center" mb="8">
            Edit your Post
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap="4">
              <VStack align="stretch" spacing="4">
              <Controller
                      control={control}
                      name="images"
                      render={({ field }) => (
                        <Flex
                          justify="center"
                          align="center"
                          flexDirection="column"
                          borderWidth="2px"
                          borderStyle="dotted"
                          borderColor="gray.300"
                          backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                          borderRadius={20}
                          p={5}
                          w="full"
                          h="auto"
                        >
                          {!previewImages.length && !postData?.data?.images?.length ? (
                            <label>
                              <Flex flexDirection="column" alignItems="center" justifyContent="center">
                                <Box>
                                  <AiOutlineCloudUpload
                                    size="48px"
                                    color={colorMode === 'light' ? 'white' : '#345430'}
                                  />
                                </Box>
                                <Text fontSize="lg">Click to upload</Text>
                              </Flex>
                              <Text mt="32px" fontSize="sm" color="black">
                                Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF, or TIFF less than 1MB.
                                Maximum of four images.
                              </Text>
                              <Input
                                  {...register("images")}
                                  type="file"
                                  id="upload-image"
                                  onChange={(e) => {
                                    field.onChange(e); 
                                    handleFileUpload(e); 
                                  }}
                                  accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                                  multiple
                                  style={{ display: 'none' }}
                                  // disabled={fileLimit}
                                  ref={fileInputRef}
                                />
                            </label>
                          ) : (
                            <>
                              {/* Show existing images (from backend) and new previews */}
                              <Flex wrap="wrap" gap="10px">
                                {/* Render Backend Images */}
                                {backendImages.map((image, index) => (
                                  <Box key={`backend-${index}`} position="relative">
                                    <Image src={image} alt={`Backend ${index}`} h="300px" w="300px" objectFit="cover" />
                                    <Button
                                      position="absolute"
                                      top="5px"
                                      right="5px"
                                      size="sm"
                                      onClick={() => handleImageRemove(index, true)} // Indicate backend
                                      colorScheme="red"
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                ))}

                                {/* Render Previews of New Files */}
                                {previewImages.map((preview, index) => (
                                  <Box key={`preview-${index}`} position="relative">
                                    <Image src={preview} alt={`Preview ${index}`} h="300px" w="300px" objectFit="cover" />
                                    <Button
                                      position="absolute"
                                      top="5px"
                                      right="5px"
                                      size="sm"
                                      onClick={() => handleImageRemove(index, false)} // Indicate new file
                                      colorScheme="red"
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                ))}
                              </Flex>
                       {(previewImages.length > 0 || backendImages.length > 0) && (
                          <>
                        <Input
                          {...register("images")}
                          type="file"
                          id="upload-image"
                          onChange={(e) => {
                            field.onChange(e); 
                            handleFileUpload(e); // Handle file upload API if needed
                          }}
                          accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                          multiple
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                        />

                            <Button
                              type="button"
                              mt="2"
                              backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                              color={colorMode === 'light' ? 'white' : '#345430'}
                              size="sm"
                              onClick={handleButtonClick}
                            >
                              Upload Another Image
                            </Button>
                          </>
                        )}
                              <Button
                                type="button"
                                mt="2"
                                backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                                color={colorMode === 'light' ? 'white' : '#345430'}
                                size="sm"
                                onClick={() => resetFileState()}
                              >
                                Reset
                              </Button>
                            </>
                          )}
                        </Flex>
                      )}
                    />

            <Controller
                  control={control}
                  name="name"
                  rules={{ maxLength: 255 }}
                  render={({ field }) => (
                    <>
                     <label>{language === "en" ? "Please enter title of post" : (language === 'yo' ? 'E ba wa ko ori igbekale yi' : 'Por favor, introduce el título de la publicación')}</label>
                    <Input
                      {...field}
                      size="lg"
                      borderColor={errors?.name ? "red.500" : "gray.300"}
                      placeholder={language === "en" ? "Enter name..." : "Fọwọsi orukọ..."}
                      // isInvalid={!!errors?.name}
                    />
                    </>
                  )}
                />
                {errors?.name && <Text color="red.500">{errors?.name?.message}</Text>}

                <Controller
                  control={control}
                  name="type"
                  // rules={{ required: "Type is required" }}
                  render={({ field }) => (
                    <>
                     <label>{language === "en" ? "Select Category..." : (language === 'yo' ? 'Iru Nkan ti e fẹ́ ko...' : 'Seleccionar Categoría...')}</label>
                    <Select
                    {...register("type")}
                    placeholder={language === "en" ? "Select Category..." : (language === 'yo' ? 'Iru Nkan ti e fẹ́ ko...' : 'Seleccionar Categoría...')}
                    {...field}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderColor={colorMode === 'light' ? '#345430' : 'white'}
                    fontFamily="Inter"
                    fontSize="20px"
                    _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                    width={"100%"}
                    height={"60px"}
                  >
                    {filter.map((option) => (
                      <option key={option.id} value={typeof option.type === "string" ? option.type : option.type[language]}>
                        <Flex align="center" textTransform={'capitalize'} justifyContent={'space-between'}>
                          <Image src={option.icon} boxSize="100px" p={10} />
                          {typeof option.type === "string" ? option.type : option.type[language]}
                        </Flex>
                      </option>
                    ))}
                  </Select>
                  </>
                  )}
                />
                 {errors?.type && <Text color="red.500" fontSize="sm">{errors?.type?.message}</Text>}
                             {/* <Controller
                  control={control}
                  name="name"
                  rules={{ maxLength: 255 }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      size="lg"
                      borderColor={errors?.name ? "red.500" : "gray.300"}
                      placeholder={language === "en" ? "Enter name..." : "Fọwọsi orukọ..."}
                      // isInvalid={!!errors?.name}
                    />
                  )}
                />
                {errors?.name && <Text color="red.500">{errors?.name?.message}</Text>} */}

                <VStack align="stretch" spacing="4">
                  {fields.map((field, index) => (
                    <Flex key={field?.id} alignItems="center">
                      <Controller
                        control={control}
                        name={`otherNames.${index}.value`}
                        render={({ field }) => (
                          <>
                     <label>{language === 'en' ? "Other Names...  " : 
                              (language === 'yo' ? 'Awọn Oruko Miiran...' 
                                : "Otros Nombres...")}</label>
                          <Input
                            {...field}
                            placeholder={`Other Name ${index + 1}`}
                            size="lg"
                            borderColor={errors?.otherNames?.[index]?.value ? "red.500" : "gray.300"}
                            // isInvalid={!!errors.otherNames}
                          />
                          </>
                        )}
                      />
                      <Button
                        ml={2}
                        colorScheme="red"
                        size="sm"
                        onClick={() => remove(index)}
                      >
                        <MdDelete />
                      </Button>
                    </Flex>
                  ))}
                   <Button onClick={() => append({ value: "" })}>Add Name</Button>
  
                </VStack>

                <Controller
                  control={control}
                  name="description"
                  rules={{  maxLength: 1024 }}
                  render={({ field }) => (
                    <>
                    <label>{language === "en" ? "Description... " : (language === 'yo' ? 'Apejúwe Akole yi...' : "Descripción...")}  </label>
                    <Textarea
                      {...field}
                      placeholder="Description"
                      size="lg"
                      borderColor={errors?.description ? "red.500" : "gray.300"}
                    />
                    </>
                  )}
                />
              {errors.description &&    <Text color="red.500" fontSize="sm">{errors.description?.message}</Text>}

                <Controller
                  control={control}
                  name="uses"
                  rules={{ maxLength: 1024 }}
                  render={({ field }) => (
                    <>
                    <label>{language === "en" ? "Uses..." : (language === 'yo' ? 'Iwulo  Akole yi... ' : 'Usos...')} </label>
                    <Textarea
                      {...field}
                      placeholder="Uses"
                      size="lg"
                      borderColor={errors?.uses ? "red.500" : "gray.300"}
                    />
                    </>
                  )}
                />
               {errors.uses &&<Text color="red.500" fontSize="sm">{errors?.uses?.message}</Text>}
              </VStack>
              <Button
                isLoading={loading || isSubmitting}
                // isDisabled={isSubmitting || !isValid} 
                type='submit'
                loadingText="Editing Post..."
                backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                color={colorMode === 'light' ? 'white' : '#345430'}
                size="sm"
                // width={'full'}
                // disabled={!isValid}
                marginTop={'10px'}
              >
                Submit
              </Button>
            </Flex>
          </form>
        </Box>
      </motion.div>
      {showToast && (
                <ToastNotification
                  description={toastConfig?.description}
                  status={toastConfig?.status}
                  onClose={() => setShowToast(false)}
                />
              )}
    </motion.div>
  );
};

export default EditPost;