import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useColorMode,
  useToast
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import BankDetails from './BankDetails';
import { selectAccessToken, selectCurrentUser } from '../../slice/userSlice';
import axios from 'axios';
import { User } from '../../types/interface';
import { useParams } from 'react-router-dom';

interface PlanUpgradeFormData {
  plan: string;
}

const PlanUpgrade: React.FC = () => {
  const { register, handleSubmit, control, formState: { isSubmitting } } = useForm<PlanUpgradeFormData>();
  const currentUser = useSelector(selectCurrentUser) as User;
  const { storeId } = useParams<{ storeId: string }>();
  const [isConfirming, setIsConfirming] = useState(false);
  const { colorMode } = useColorMode();
  const toast = useToast();
  const accessToken = useSelector(selectAccessToken); 


  const onSubmit = async (data: PlanUpgradeFormData) => {
    const { plan } = data;
    const paymentDate = new Date().toISOString();

    const paymentDetails = {
      name: currentUser.name,
      username: currentUser.username,
      tier: plan,
      paymentDate,
    };

    try {


        // Send data to the API with storeId from params
        await axios.post(
          `https://ewe-api.herokuapp.com/api/stores/${storeId}/requesttoken`,
          paymentDetails,
          {
            headers: {
              'Content-Type': 'application/json', // Assuming it's JSON; update if different
              'Authorization': `Bearer ${accessToken}`,
              'Access-Control-Allow-Origin': '*',
            },
          }
        );

      toast({
        title: "Payment Verified",
        description: `Your payment for ${plan} has been verified.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsConfirming(false);
    } catch (error) {
      toast({
        title: "Payment Verification Failed",
        description: "There was an error verifying your payment. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpgradeClick = () => {
    setIsConfirming(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Upgrade Your Plan</title>
      </Helmet>
      <Box padding={{ base: '10px', md: '50px' }}>
        {/* <Flex justifyContent="center" mb={10}>
          <Button onClick={() => window.history.back()}>Back</Button>
        </Flex> */}
        <VStack
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          spacing={4}
          maxW="lg"
          mx="auto"
          align="start"
        >
          <Heading size="lg" textAlign="center" width="100%">
            Upgrade Your Plan
          </Heading>

          <FormControl>
            <FormLabel>Choose your plan</FormLabel>
            <Controller
              name="plan"
              control={control}
              defaultValue="tier_one"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="tier_one">Tier One - 10 tokens - 2,000 NGN (Monthly)</Radio>
                    <Radio value="tier_two">Tier Two - 15 tokens - 5,000 NGN (Monthly)</Radio>
                    <Radio value="tier_three">Tier Three - 20+ tokens - 10,000 NGN (Monthly)</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>

          <BankDetails />

          {isConfirming ? (
            <VStack spacing={4} width="100%">
              <Text color="gray.500" mt={4}>
                Please confirm your payment for the selected plan.
              </Text>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="green"
                size="lg"
                width="100%"
              >
                Confirm Payment
              </Button>
            </VStack>
          ) : (
            <Button
              onClick={handleUpgradeClick}
              colorScheme="green"
              size="lg"
              mt={4}
              width="100%"
            >
              Upgrade Plan
            </Button>
          )}

          <Text color="gray.500" mt={4}>
            Your payment will be verified within three days and your account will be activated.
            Please ensure you add "Upgrade Plan - [Tier Level]" to the transfer reason.
          </Text>
          <Text color="gray.500" mt={4}>
            Once the alert has been verified, your store will be opened for creating products.
          </Text>
        </VStack>
      </Box>
    </motion.div>
  );
};

export default PlanUpgrade;
