import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState, Store } from "../types/interface";
// import { createSelector } from "@reduxjs/toolkit";

export interface StoresState extends EntityState<Store, string> {
  ids: string[];
  entities: Record<string, Store>;
  phoneNumber: string | null; 
}

export const storesAdapter = createEntityAdapter<Store>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: StoresState = storesAdapter.getInitialState({
  ids: [],
  entities: {},
  phoneNumber: null,
});

const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    addStore: storesAdapter.addOne,
    addStores: storesAdapter.addMany,
    setStorePhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
  },
});

export const { addStore, addStores, setStorePhoneNumber } = storeSlice.actions;


// Selector to extract user IDs from the stores data
export const selectStoreUserIds = createSelector(
  (state: RootState) => state?.api?.storesApi?.queries['getStores']?.data,
  (stores) => {
    // console.log("Store Data:", stores);
    return stores?.data?.map((store: { user: { id: any; }; }) => store?.user?.id) || [];
  }
);



  
export default storeSlice.reducer;
