import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  useColorMode,
  Divider,
  Text,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { selectCurrentUser } from '../../slice/userSlice';
import {
  User
} from '../../types/interface';
import {
  useFollowUsersMutation,
  useLazyGetUserProfileQuery,
} from '../../api/userApi';
import { AutoBackButton, ToastNotification } from '../../components';
import UserInfo from './UserInfo';
import SegmentSelector from './SegmentSelector';
import ContentDisplay from './ContentDisplay';
import { useLazyGetUserQuestionQuery } from '../../api/questions';
import { useLazyGetUserArticleQuery } from '../../api/articles';
import { useLazyGetUserPostQuery } from '../../api/postApi';

const UserProfile = () => {
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as User;
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const [triggerUserProfile, { data: userData, isLoading: userLoading, isError: userError }] = useLazyGetUserProfileQuery();
  const [triggerUserQuestions] = useLazyGetUserQuestionQuery();
  const [triggerUserArticles ] = useLazyGetUserArticleQuery();
  const [triggerUserPosts]  = useLazyGetUserPostQuery();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });


  useEffect(() => {
    if (userId) {
      triggerUserProfile(userId, true); // Fetch user profile data, prefer cache if available
      triggerUserPosts(userId, true); // Fetch user post data, prefer cache if available'
    }
  }, [userId, triggerUserProfile, triggerUserPosts]);


  // console.log('User Data: ', userData);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [selectedSegment, setSelectedSegment] = useState('Post');
  
  
  useEffect(() => {
    if (userId) {
      if (selectedSegment === 'Articles') {
        triggerUserArticles(userId);
      } else if (selectedSegment === 'Question') {
        triggerUserQuestions(userId);
      } else if (selectedSegment === 'Post' ) {
        triggerUserPosts(userId);
      }
    }
  }, [userId, selectedSegment, triggerUserArticles, triggerUserQuestions, triggerUserPosts]);


  const handleSegmentChange = (segment: string) => {
    setSelectedSegment(segment);
    navigate(`/user/${userId}/${segment.toLowerCase()}`); // Route to selected segment
  };


  useEffect(() => {
    const pathSegment = window.location.pathname.split('/').pop();
    const validSegments = ['post', 'article', 'store', 'question'];
    if (pathSegment && validSegments.includes(pathSegment)) {
      setSelectedSegment(pathSegment.charAt(0).toUpperCase() + pathSegment.slice(1));
    }
  }, [navigate]);  
  
  

  const [followUsersMutation] = useFollowUsersMutation();

  const handleFollowUser = async () => {
    if (userId) {
      try {
        const response = await followUsersMutation({ userId });

        if ('data' in response && response.data?.statusCode === 201) {
          setToastConfig({
            description: response.data.data.message,
            status: 'success',
          });
          setShowToast(true);
          console.log('User followed successfully:', response);
          window.location.reload();
        } else if ('error' in response) {
          const errorMessage = 'An error occurred while following the user.';

          setToastConfig({
            description: errorMessage,
            status: 'error',
          });
          setShowToast(true);
        }
      } catch (error) {
        setToastConfig({
          description: 'An error occurred while following the user.',
          status: 'error',
        });
        setShowToast(true);
      }
    } else {
      console.error('User ID is undefined');
    }
  };


  // const locationName = useSelector(
  //   (state: RootState) => state.reverseGeocode.locationName
  // );

  if (userLoading) {
    return (
      <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <Center h="100vh" flexDirection="column">
          <Spinner size="lg" color="teal.500" mb={4} />
          <Text fontSize="xl" color="teal.600">Loading...</Text>
        </Center>
      </Box>
    );
  }



  if (userError) {
    return (
      <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <Text color="red.500" fontSize="xl">An error occurred while fetching user data. Please try again later.</Text>
      </Box>
    );
  }
  

  return (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: 'easeIn' }}>
      <Helmet><title>User Profile Page</title></Helmet>
      <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <AutoBackButton />
        <UserInfo 
        userData={userData} 
        currentUser={currentUser} 
        userId={userId} 
        userLoading={userLoading}
        userError={userError}
        colorMode={colorMode} 
        handleFollowUser={handleFollowUser}
        navigate={navigate} />
        <SegmentSelector 
        selectedSegment={selectedSegment} 
        handleSegmentChange={handleSegmentChange} 
        userData={userData} 
        colorMode={colorMode} />
        <Divider my={8} />
        <ContentDisplay 
        selectedSegment={selectedSegment} 
        userId={userId}
        userData={userData} 
          />
        {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} onClose={() => setShowToast(false)} />}
      </Box>
    </motion.div>
  );
};

export default UserProfile;

