import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout, PostAndQuestionSlider, SlideTransitionContainer } from './components';
import { 
  About,
  Bookmarks,
  Contact, 
  CreatePost, 
  Donate, 
  ErrorPage, 
  ForgotPassword,
  Home, 
  Login, 
  PostById, 
  Posts, 
  Project, 
  ResetPassword, 
  SearchPage, 
  Shop, 
  SignUp, 
  UserProfile, 
  UserProfileSettings, 
  ArticleById,
  Article,
  CreateArticle,
  EditPost,
} from './pages';
import { useEffect } from 'react';
import CreateQuestion from './pages/CreateQuestion';
import Questions from './components/Questions/Questions';
import VerifyEmail from './pages/VerifyEmail';
import Stores from './pages/Stores/Stores';
import CreateStores from './pages/Stores/CreateStores';
import EditStore from './pages/Stores/EditStore';
import UserStores from './pages/Stores/UserStores';
import CreateStoreProducts from './pages/Stores/CreateStoreProducts';
import QuestionById from './pages/QuestionById';
import PlanUpgrade from './pages/Stores/PlanUpgrade';
import StoreProductDetails from './pages/Stores/StoreProductDetails';
import Cart from './components/ProductCart';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EditStoreProducts from './pages/Stores/EditStoreProducts';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router basename='/'>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="donate" element={<Donate />} />
          <Route path="contact" element={<Contact />} />
          <Route path="project" element={<Project title={''} content={''} image={''} />} />
          <Route path="profile" element={<UserProfile />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="resetpassword/:tokenId/:userId" element={<ResetPassword />} />
          <Route path="shop" element={<Shop />} />
          <Route path="search" element={<SearchPage />} />

          <Route path="user">
            <Route path=":userId">
              <Route index element={<UserProfile />} />
              <Route path="settings" element={<UserProfileSettings />} />
              <Route path="bookmarks" element={<Bookmarks />} />
              <Route path="article" element={<UserProfile />} />
              <Route path="stores" element={<UserProfile />} />
              <Route path="post" element={<UserProfile />} />
              <Route path="question" element={<UserProfile />} />
              {/* <Route path="questions" element={<UserProfile />} /> */}
              <Route path="posts" element={
                <PostAndQuestionSlider>
                  <Posts />
                </PostAndQuestionSlider>} 
              />
              <Route path="questions" element={
                <PostAndQuestionSlider>
                  <Questions />
                </PostAndQuestionSlider>} 
              />
              {/* <Route path="cart" element={<Cart cart={[]} isOpen={false} toggleCart={''} language={''} />} /> */}
              <Route path="articles" element={<Article />} />
              <Route path="market" element={<Stores />} />
              <Route path="create-store" element={<CreateStores />} />
              <Route path="stores/:storeId/edit-store" element={<EditStore />} />
              <Route path="stores/:storeId" element={<UserStores />} />  {/* Removed leading slash */}
              <Route path="stores/:storeId/products/:productId" element={<StoreProductDetails />} /> 
              <Route path="stores/:storeId/create-products" element={<CreateStoreProducts/>} />
              <Route path="stores/:storeId/plan-upgrade" element={<PlanUpgrade />} />
              <Route path="stores/:storeId/products/:productId/edit-product" element={<EditStoreProducts />} />

              <Route path="articles/:articleId" element={<ArticleById />} />
              <Route path="posts/:postId" element={<PostById />} />
              <Route path="questions/:questionId" element={<QuestionById />} /> 
              <Route path="edit-post/:postId" element={<EditPost />} />
              {/* <Route path="plan-upgrade" element={<PlanUpgrade />} /> */}
              <Route path="create-post" 
                element={
                  <SlideTransitionContainer>
                    <CreatePost />
                  </SlideTransitionContainer>
                } 
              />
              <Route path="create-question" 
                element={
                  <SlideTransitionContainer>
                    <CreateQuestion />
                  </SlideTransitionContainer>
                } 
              />
              <Route path="create-article" element={<CreateArticle />} />
            </Route>
          </Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
