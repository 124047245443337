import { useState, useEffect, useRef } from "react";
import {
  Input,
  Button,
  Textarea,
  Checkbox,
  CheckboxGroup,
  VStack,
  Box,
  Text,
  useColorMode,
  Heading,
  Wrap,
  WrapItem,
  FormLabel,
  Image,
  Flex,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastNotification, FormPhoneInput } from "../../components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { selectAccessToken, selectCurrentUser } from "../../slice/userSlice";
// import { updateStore } from "../../slice/storeSlice";
import { RootState, User, ItemCategory, Store } from "../../types/interface";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useGetStoreByUserIdQuery,   useUpdateStoreMutation } from '../../api/stores';


interface StoreData {
  name: string;
  description: string;
  categories: ItemCategory[];
  image: string;
  phoneNumber: string;
}

const EditStore = () => {
  const { storeId } = useParams<{ storeId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const accessToken = useSelector(selectAccessToken);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
    reset,
    trigger,
    register,
  } = useForm<StoreData>({
    defaultValues: {
      name: '',
      description: '',
      phoneNumber: '',
      categories: [ItemCategory.OIL], 
      image: '',
    },
    criteriaMode: 'all',
    mode: 'onChange', // Trigger validation only for changed fields
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: "", status: "" });
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const [previewImages, setPreviewImages] = useState<string>("");
  const [backendImages, setBackendImages] = useState<string>(""); // Existing images
  const { data: store, isLoading, isError } = useGetStoreByUserIdQuery(currentUser.id);
  const [updateStore, { isLoading: isUpdating }] = useUpdateStoreMutation();

  // console.log("Store:", store);

  useEffect(() => {
    if (store && store.data?.length > 0) {
      const storeData = store.data[0]; // Accessing the first store item
      setValue("name", storeData?.name || "");
      setValue("description", storeData?.description || "");
      setValue("categories", storeData?.category || []);
      setValue("phoneNumber", storeData?.phoneNumber || "");
      setValue("image", storeData?.image || "");
      setPreviewImages(storeData?.image || "");
    } else if (isError) {
      setToastConfig({
        description: "Failed to load store data.",
        status: "error",
      });
      setShowToast(true);
    }
  }, [store, isError, setValue]);
  
  

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("file", file);

      setLoading(true);

      try {
        const response = await axios.post(
          `https://ewe-api.herokuapp.com/api/files/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response?.data?.data) {
          const fileUrl = response.data.data;
          setValue("image", fileUrl);
          setPreviewImages(fileUrl);
          setToastConfig({
            description: "Success! Image uploaded successfully.",
            status: "success",
          });
          setShowToast(true);
        }
      } catch (error) {
        setToastConfig({
          description: "An error occurred during the upload.",
          status: "error",
        });
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = async (data: StoreData) => {
    setLoading(true);

    const body = {
      name: data.name,
      description: data.description,
      category: data.categories,
      image: data.image,
      phoneNumber: data.phoneNumber,
    };

    try {
      const response =  await updateStore({ storeId, ...body }).unwrap(); 
      // console.log("Store Edit Response: ", response)
      const responseData = response.data;

      setToastConfig({ description: "Store successfully updated!", status: "success" });
      setShowToast(true);
      reset();
      navigate(`/user/${currentUser.id}/stores`);
    } catch (error) {
      setToastConfig({ description: "Failed to update store", status: "error" });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  if (isUpdating) {
    return (
      <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <Center h="100vh" flexDirection="column">
          <Spinner size="lg" color="teal.500" mb={4} />
          <Text fontSize="xl" color="teal.600">Updating...</Text>
        </Center>
      </Box>
    );
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === "light" ? "white" : "#345430",
        color: colorMode === "light" ? "#345430" : "white",
      }}
    >
      <motion.div
        style={{
          backgroundColor: colorMode === "light" ? "white" : "#345430",
          paddingBottom: "20px",
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
      >
        <Box fontFamily="Inter" paddingTop={20} mx={{ base: 4, md: 10 }} pb={10}>
          <Heading
            fontFamily="Inter"
            as="h1"
            fontSize={{ base: "24px", md: "32px" }}
            textAlign="center"
            mb={6}
          >
            {language === "en"
              ? "Edit Store"
              : language === "yo"
              ? "Ṣatunkọ Ọjà"
              : "Editar Tienda"}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={6} align="stretch">
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <Flex
                  justify="center"
                  align="center"
                  flexDirection="column"
                  borderWidth="2px"
                  borderStyle="dotted"
                  borderColor="gray.300"
                  style={{
                    backgroundColor: colorMode === "light" ? "#E0EAE0" : "white",
                    color: colorMode === "light" ? "white" : "#345430",
                  }}
                  borderRadius={20}
                  p={5}
                  w="full"
                  h="auto"
                  {...field}
                >
                  <FormLabel htmlFor="file-upload" display="none">
                    Upload Image
                  </FormLabel>

                  <Input
                    id="file-upload"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e)}
                    accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff, image/jpg"
                    disabled={!!previewImages} // Disable input if an image already exists
                  />

                  {!previewImages && ( // Show the upload button only if no image exists
                    <Button
                      fontFamily="Inter"
                      colorScheme="teal"
                      borderRadius={20}
                      p={5}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <AiOutlineCloudUpload size="48px" />
                      Upload Image
                    </Button>
                  )}

                  {previewImages && (
                    <Box
                      width="full"
                      height="full"
                      borderRadius="lg"
                      overflow="hidden"
                      position="relative"
                      mt={4}
                    >
                      <Image
                        src={previewImages}
                        alt="Preview"
                        h="300px"
                        w="300px"
                        objectFit="cover"
                        borderRadius="lg"
                      />
                      <Button
                        size="xs"
                        position="absolute"
                        top="0"
                        right="0"
                        colorScheme="red"
                        onClick={() => {
                          setPreviewImages(""); // Clear the preview
                          setValue("image", ""); // Clear the image in the form state
                        }}
                      >
                        <MdDelete />
                      </Button>
                    </Box>
                  )}

                  {loading && <p>Loading...</p>}
                </Flex>
              )}
            />
           <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Store Name"
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.300"
                backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                required
              />
            )}
          />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Store Description"
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor="gray.300"
                  height={500}
                  backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  required
                />
              )}
            />
                <Controller
                name="categories"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Text fontSize="lg" mb={2}>Select Store Categories</Text>
                    <CheckboxGroup
                      value={field.value} // Ensures the selected categories are displayed
                      onChange={(selectedValues) => field.onChange(selectedValues)}
                    >
                      <Wrap spacing={4}>
                        {Object.values(ItemCategory).map((category) => (
                          <WrapItem key={category}>
                            <Checkbox value={category}>{category}</Checkbox>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </CheckboxGroup>
                  </Box>
                )}
              />
           
           <FormPhoneInput
                          name="phoneNumber"
                          label="Phone Number"
                          control={control}
                          register={register}
                          rules={{
                              pattern: {
                                  value: /^[0-9+\s()-]*$/,
                                  message: 'Invalid phone number',
                              },
                          }} 
                          errors={errors}       
                          onKeyUp={() => {
                            trigger("phoneNumber");
                         }}
                            />

          
              <Button
                fontFamily="Inter"
                bg="green.500"
                color="white"
                _hover={{ bg: "green.600" }}
                type="submit"
                borderRadius="lg"
                w="full"
                isLoading={isSubmitting}
              >
                {language === "en"
                  ? "Update Store"
                  : language === "yo"
                  ? "Tunse Ile itaja"
                  : "Actualizar Tienda"}
              </Button>
            </VStack>
          </form>
        </Box>
      </motion.div>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </motion.div>
  );
};

export default EditStore;
