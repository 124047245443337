import React, { useEffect, useState } from "react";
import { Flex, Box, useColorMode, Text, Button, Center, Spinner } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Post, RootState, User } from "../../types/interface";
import { selectCurrentUser } from "../../slice/userSlice";
import { useSelector } from "react-redux";
import PostContent from "../Posts/PostContent";
import { useNavigate } from "react-router-dom";
import { useLazyGetUserPostQuery } from "../../api/postApi";

interface UserPostsProps {
  userId: string;
}

const UserPosts: React.FC<UserPostsProps> = ({
  userId
}) => {
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const isAuthenticated = useSelector(
    (state: RootState) => state.users.isLogin
  );
  const [triggerUserPosts, { data: posts, isLoading: postsLoading }]  = useLazyGetUserPostQuery();
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      triggerUserPosts(userId, true); // Fetch user post data, prefer cache if available'
    }
  }, [userId, triggerUserPosts]);

  const [allPosts, setAllPosts] = useState<Post[]>([]);

  useEffect(() => {
    if (posts) setAllPosts(posts?.data?.posts || []);
  }, [posts]);

  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-post`;
      navigate(createPostUrl);
    } else {
      navigate("/login");
    }
  };

  const updatePostData = (postId: string, newData: any) => {
    setAllPosts((prevPosts: any[]) =>
      prevPosts.map((post: any) =>
        post._id === postId ? { ...post, ...newData } : post
      )
    );
  };

  const handlePostCardClick = (postId: string) => {
    if (isAuthenticated) {
      navigate(`/user/${userId}/posts/${postId}`);
    } else {
      navigate('/login');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === "light" ? "white" : "#345430",
        color: colorMode === "light" ? "white" : "#345430",
      }}
    >
      <motion.div
        style={{
          backgroundColor: colorMode === "light" ? "white" : "#345430",
          color: colorMode === "light" ? "#000" : "white",
          paddingBottom: "20px",
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
      >
        <Box
          paddingTop="20px"
          paddingBottom="20px"
          marginRight={{ base: "15", md: "40" }}
          marginLeft={{ base: "15", md: "40" }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            paddingBottom="20px"
          >
            {postsLoading ? (
             <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
             <Center h="100vh" flexDirection="column">
               <Spinner size="lg" color="teal.500" mb={4} />
               <Text fontSize="xl" color="teal.600">Loading...</Text>
             </Center>
           </Box>
            ) : allPosts?.length > 0 ? (
              allPosts?.map((post: any) => (
                <PostContent
                  key={post._id}
                  post={post}
                  currentUser={currentUser}
                  isAuthenticated={isAuthenticated}
                  handlePostCardClick={handlePostCardClick}
                  isLoading={postsLoading}
                  updatedPostData={updatePostData}
                />
              ))
            ) : !postsLoading && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={20}
                flexDir={"column"}
              >
               <Text
                  textAlign="center"
                  fontSize="xl"
                  fontWeight="bold"
                  color={colorMode === "light" ? "#345430" : "white"}
                >
                  No posts to display
                </Text>
                {!postsLoading && (currentUser.id === userId) && (
                  <Button
                    onClick={redirectToCreatePost}
                    mt={0}
                    bg={colorMode === "light" ? "#345430" : "white"}
                    padding={"20px"}
                    color={colorMode === "light" ? "white" : "#000"}
                    variant="solid"
                    borderRadius={"10px"}
                    _hover={{
                      color: "#345430",
                      backgroundColor: "#F7F7F9",
                    }}
                    size="md"
                  >
                    {language === "en"
                  ? "Create Post"
                  : language === "yo"
                  ? "Fún wa ní ìwádí kan"
                  : "Crear Publicación"}
                  </Button>
                )}
              </Box>
            )}
          </Flex>
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default UserPosts;
