  import { useEffect, useState } from 'react';
  import { Box, Flex, Image, Text, Skeleton, Icon, useColorMode } from '@chakra-ui/react';
  import { motion } from 'framer-motion';
  import { GoVerified } from 'react-icons/go';
  import { useParams, Link } from 'react-router-dom';
  import { Helmet } from 'react-helmet';
  import { useSelector } from 'react-redux';
  import { RootState } from '../store/store';
  import PostButtons from '../components/Posts/PostButtons';
  import { selectIsAuthenticated } from '../slice/userSlice';
  import { Post } from '../types/interface';
  import { useGetPostQuery } from '../api/postApi';
  import { AutoBackButton, Comment, PostMenu, formatCreatedAtDate } from '../components';
  import { BiError } from "react-icons/bi";
  import { FaMapMarkerAlt } from 'react-icons/fa';
  import TranslationToggle from '../components/Posts/TranslationToggle';
  import { getCategoryIcon } from '../components/getCategoryIcon';
  
  const PostById: React.FC = () => {
    const { postId } = useParams<{ postId?: string }>();
    const language = useSelector((state: RootState) => state.language.language);
    const { colorMode } = useColorMode();
    const { data: postData, isLoading: isLoadingPost, isError: isErrorPost } = useGetPostQuery(postId, {
      refetchOnMountOrArgChange: false,
    });
    


    const [post, setPost] = useState<Post | null>(null);
    const [translatedPost, setTranslatedPost] = useState<Partial<Post> | null>(null);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
      if (postData && postData.data) {
        setPost(postData.data);
      }
    }, [postData]);
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const updatePostData = (newData: Partial<Post>) => {
      setPost(prevPost => prevPost ? ({
        ...prevPost,
        ...newData,
        location: newData.location ?? prevPost.location,
        // Handle other fields similarly if necessary
      }) : null);
    };  
    // Reset translated post when post changes
    useEffect(() => {
      setTranslatedPost(null);
    }, [post])    


    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
        style={{
          backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#588552',
          color: colorMode === 'light' ? "white" : '#345430'
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{post?.name || 'Post'} page</title>
        </Helmet>
  
        {(isLoadingPost) && !isErrorPost && (
          <Box textAlign="center" p={10}>
            <Skeleton height="500px" borderRadius={20} color={"transparent"} />
          </Box>
        )}
        {!isLoadingPost&& (isErrorPost) && (
          <div>Error occurred while fetching data.</div>
        )}
        {!isLoadingPost && (post) && (
          <Box
            paddingTop={20}
            pb={10}
            marginRight={{ base: "15", md: "20" }}
            marginLeft={{ base: "15", md: "20" }}
          >
            <AutoBackButton />
            <Flex justifyContent="center" color={colorMode === 'light' ? '#345430' : 'white'}
              alignItems="start" flexDirection={"column"} paddingBottom="20px">
              <Box
                key={post?._id}
                width={{ base: "100%" }}
                mx="auto"
                mb="4"
                p="5"
                borderRadius="0"
                bg="transparent"
                border="none"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Flex justify="space-between" alignItems="center">
                  <Box display={'block'} alignItems={'start'}>
                    <Text
                      textAlign="start"
                      paddingBottom="5px"
                      fontFamily="Inter"
                      paddingTop="5px"
                      fontWeight={600}
                      fontSize={{ base: "16px", md: "18px", lg: "20px" }}
                    >
                      {isAuthenticated ? (
                        <Link to={`/user/${post?.user?._id}`}>
                          @{post?.user?.username}
                        </Link>
                      ) : <Link to={'/login'} />}
                    </Text>
                    <Text
                      paddingBottom="5px"
                      textAlign="start"
                      fontFamily="Inter"
                      fontWeight={600}
                      fontSize={{ base: "12px", md: "16px", lg: "18px" }}
                    >
                      {formatCreatedAtDate(post?.createdAt)}
                    </Text>
                    {(post?.location) && (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start', paddingBottom: '8px' }}>
                        <FaMapMarkerAlt color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                        <Text color={colorMode === 'light' ? '#000' : 'white'} textAlign={'start'} pl={2} fontSize={{ base: '12px' }}>
                          {post?.location}
                        </Text>
                      </div>
                    )}
                  </Box>
                  {isAuthenticated && post && post?.user && (
                    <PostMenu postId={post?._id} ownerId={post?.user?._id} postVerified={post.verify} />
                  )}
                </Flex>

                {((post?.images && post.images.length > 0)) && (
                  <Box mt={2} display="flex" flexWrap="wrap">
                    {(post?.images || []).map((image: string, imageIndex: number) => (
                      <Image
                        key={imageIndex}
                        src={image}
                        alt={post?.name}
                        borderRadius="10px"
                        style={{
                          marginRight: '8px',
                          marginBottom: '8px',
                          maxWidth: '100%',
                          flex: '1 0 300px', // Adjust the width as needed
                        }}
                        width="500px"
                        height="500px"
                        objectFit="cover"
                      />
                    ))}
                  </Box>
                )}    
                <Box flex="1" textAlign="start" fontFamily="Inter">
                  <Text
                    textAlign="start"
                    paddingBottom="10px"
                    fontFamily="Inter"
                    paddingTop="10px"
                    fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                    textTransform="capitalize"
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems={"center"}
                    gap={"20px"}
                    mb={4}
                    className="no-select"
                  >
                  {post && (post?.name )}
              {post &&(
            
                    <span >
                    <Image
                  objectFit="cover"
                  width="30px"
                  height="30px"
                  src={getCategoryIcon(post?.type, language) as string} 
                  alt={"Category"}
                  />
                  </span>
              )
  }
        {post &&
         (
                  <span style={{ width: '50px', height: '50px' }}>
                    <Icon borderRadius={'10px'}>
                      {post?.verify ? (
                        <GoVerified style={{ color: colorMode === 'light' ? '#345430' : 'white', backgroundColor: colorMode === 'light' ? 'white' : '#345430' }} fontSize={25} />
                      ) : (
                        <BiError style={{ color: 'red', backgroundColor: colorMode === 'light' ? 'white' : '#345430' }} fontSize={25} />
                      )}
                    </Icon>
                  </span>
        )
  }
                  </Text>
                  { post && (
                  <Text 
                        fontSize={'20px'}
                        fontFamily="Inter"
                        fontWeight={600}
                        mb={4}
                        className="no-select"
                        >
           {language === 'en'
                  ? 'Other Names: '
                  : language === 'yo'
                  ? 'Oruko omiran: '
                  : 'Otros nombres: '}
                  <span>
                    {Array.isArray(post?.othernames)
                      ? post?.othernames.join(', ')
                      : post?.othernames}
                  </span>
                </Text>
                  )}
                     { post && (
                      <Box>
                <Text 
                fontSize={'20px'}
              fontFamily="Inter"
              fontWeight={600}
              paddingBottom="10px"
            >
              {language === 'en' ? 'Description: ' : language === 'yo' ? 'Apejuwe re:' : 'Descripción:'}
            </Text>
                <Text 
               whiteSpace="pre-wrap"
               fontFamily="Inter"
               mb={4}
               font-size='16px'
                className="no-select"
                >
                  {translatedPost ? translatedPost.description : post?.description}
                </Text>
                </Box>
                     )}
                        { post && (
                          <Box>
                <Text
              fontSize={'20px'}
              fontFamily="Inter"
              fontWeight={600}
            >
           {language === 'en' ? 'Uses: ' : language === 'yo' ? 'Iwulo re: ' : 'Usos:'}
            </Text>
                <Text 
                  whiteSpace="pre-wrap"
                  fontFamily="Inter"
                  mb={4}
                  font-size='16px'
                   className="no-select"
                >
                  {translatedPost ? translatedPost.uses : post?.uses}
                </Text>
                </Box>
                        )}
                </Box>
                <Text
            paddingTop="5px"
            textAlign="start"
            fontFamily="Inter"
            fontSize={12}
          >
            {/* Check if the post is edited by comparing createdAt and updatedAt */}
            {post?.updatedAt && post?.updatedAt !== post?.createdAt && (
            <>
              {formatCreatedAtDate(post?.updatedAt)} <span>(Edited)</span>
            </>
          )}
          </Text>
              </Box>
            </Flex>
            { post && (   
            <Box mt={4} >
            <TranslationToggle
                postId={post?._id ?? ''}
                description={post?.description ?? ''}
                uses={post?.uses ?? ''}
                setTranslatedPost={setTranslatedPost}
                postLang={post?.lang ?? 'en'}
              />

            </Box>
            )}

            {post && (
                    <Box>
                                    <PostButtons
                                      post={post} 
                                      updatedPostData={updatePostData}
                                    />
                                    <Box mt={5}>
                                      <Comment post={post} updatedPostData={updatePostData} />
                                    </Box>
                                  </Box>
            )}
          </Box>
        )}
      </motion.div>
    );
  };
  
  export default PostById;
