// UserInfo.tsx
import React from 'react';
import { VStack, Text, Flex, Button, Icon, Box, Skeleton } from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';
import { GoVerified } from 'react-icons/go';
// import { BiError } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { IoBookmarks } from 'react-icons/io5';

// Assuming a User interface with typical user properties
export interface User {
    id: string; // or number, depending on your ID type
    name: string;
    username: string;
    bio?: string; // Optional
    profilePictureUrl?: string; // Optional
    followersCount?: number; // Optional
    followingCount?: number; // Optional
  }
  
  export interface UserInfoProps {
    userData: any;       // User data passed to the component
    currentUser: any;    // Currently logged-in user data
    userId: any;       // The ID of the user being viewed
    colorMode: 'light' | 'dark'; // Can be either 'light' or 'dark'
    handleFollowUser: any; // Function to handle follow action
    userError: any;
    userLoading: any;
    navigate: any;
  }
  

const UserInfo: React.FC<UserInfoProps> = ({ userData, currentUser, userId, colorMode, handleFollowUser , userError,userLoading, navigate }) => (
   <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: 'easeIn' }}>
          <Box p={10}>
           {userError ? (
              <div>Failed to Get User Profile</div>
            ) : (
              <div>
                {userLoading && (
                  <Box flex="1">
                    <Skeleton height="200px" borderRadius={20} color={'transparent'} width="100%" />
                  </Box>
                )}
                {!userLoading && (
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'start', md: 'center' }}
                    justify="space-between"
                    gap={10}
                    fontFamily="Inter"
                  >
                    <VStack align="start" spacing={4}>
                      <Text fontSize="xl" fontWeight="bold">
                        {userData?.data?.user?.name}
                      </Text>
                      {userData?.data?.user?.username && (
                        <Flex align="center">
                          <Text fontSize={16}>@{userData?.data?.user?.username}</Text>
                          <Icon borderRadius={'10px'} ml={2}>
                            {userData?.data?.user?.verify ? (
                              <GoVerified
                                style={{
                                  color: colorMode === 'light' ? '#345430' : 'white',
                                  backgroundColor: colorMode === 'light' ? 'white' : '#345430'
                                }}
                                fontSize={25}
                              />
                            ) : (
                              // <BiError
                              //   style={{
                              //     color: 'red',
                              //     backgroundColor: 'red'
                              //   }}
                              //   fontSize={25}
                              // />
                              ""
                            )}
                          </Icon>
                        </Flex>
                      )}
                      {userData?.data?.user?.field && (
                        <Text style={{ textTransform: 'capitalize' }} fontSize={16}>
                          {userData?.data?.user?.field}
                        </Text>
                      )}
                      {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <FaMapMarkerAlt color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                        <Text color={colorMode === 'light' ? '#000' : 'white'} textAlign={'start'} p={2} fontSize={{ base: '16px', md: '20px', lg: '20px' }}>
                          {locationName}
                        </Text>
                      </div> */}
                      {userData?.data?.profile?.about && (
                        <Text style={{ textTransform: 'capitalize', textAlign: 'justify', fontSize: '16px' }}>
                          {userData?.data?.profile?.about}
                        </Text>
                      )}
                    </VStack>
                    <VStack display={'flex'} flexDirection={'column'}>
                      {currentUser.id === userId && (
                        <VStack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                          <VStack alignItems="start" spacing={4}>
                            <Button
                              onClick={() => {
                                navigate(`/user/${userId}/settings`);
                              }}
                              disabled={currentUser.id !== userId}
                              cursor={'pointer'}
                            >
                              <FaCog color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                            </Button>
                          </VStack>
                          <VStack alignItems="start" spacing={4}>
                            <Button
                              onClick={() => {
                                navigate(`/user/${userId}/bookmarks`);
                              }}
                              disabled={currentUser.id !== userId}
                              cursor={'pointer'}
                            >
                              <IoBookmarks color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                            </Button>
                          </VStack>
                        </VStack>
                      )}
                      <VStack display={'flex'} flexDirection={'row'}>
                        <VStack alignItems="start" spacing={4}>
                          {userId !== currentUser.id && (
                            <Button onClick={() => handleFollowUser()} disabled={userId === currentUser.id}>
                              Follow User
                            </Button>
                          )}
                        </VStack>
                        <VStack alignItems="start" spacing={4}>
                          <Button>
                            Following {userData?.data?.user?.followers.length || 0}
                          </Button>
                        </VStack>
                        <VStack alignItems="start" spacing={4}>
                          <Button>
                            Followers {userData?.data?.user?.follow.length || 0}
                          </Button>
                        </VStack>
                      </VStack>
                    </VStack>
                  </Flex>
                )}
              </div>
            )}
          </Box>
        </motion.div>

);

export default UserInfo;
