import React from "react";
import { VStack, Text, Box } from "@chakra-ui/react";

export interface UserInfoProps {
  selectedSegment: string;
  handleSegmentChange: (segment: string) => void;
  colorMode: string;
  userData: any;       
}

const SegmentSelector: React.FC<UserInfoProps> = ({
  selectedSegment,
  handleSegmentChange,
  colorMode,
  userData,
}) => {
  // Define available segments based on the user's role
  const segments = ["Post", "Question"];
  if (userData?.data?.user?.role === "SPECIALIST") {
    segments.push("Article", "Stores"); // Add "Stores" only for the profile owner if they are a specialist
  }

  return (
    <VStack
      display="flex"
      flexDirection="row"
      justifyContent="center"
      gap={10}
      p={10}
    >
      {segments.map((segment) => (
        <Text
          key={segment}
          onClick={() => handleSegmentChange(segment)}
          color={colorMode === "light" ? "#345430" : "#fff"}
          position="relative"
          cursor="pointer"
        >
          {segment}
          {selectedSegment === segment && (
            <Box
              position="absolute"
              bottom="-10px"
              left="8%"
              transform="translateX(-50%)"
              h="5px"
              w="5px"
              bg={colorMode === "light" ? "#345430" : "#fff"}
              borderRadius="50%"
            />
          )}
        </Text>
      ))}
    </VStack>
  );
};

export default SegmentSelector;
