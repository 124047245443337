import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Text, Heading, VStack, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { AutoBackButton } from "../components";


const PrivacyPolicy: React.FC = () => {
  const { colorMode } = useColorMode();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the page loads
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', color: colorMode === 'light' ? '#345430' : 'white' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Post Page</title>
      </Helmet>
      <AutoBackButton />
      <Box
        p={8}
        minHeight="100vh"
      >
        <VStack spacing={6} align="flex-start">
          <Heading as="h1" size="2xl" fontWeight="bold">
            Privacy Policy
          </Heading>
          <Text fontSize="sm">Effective Date:  2024</Text>
          <Text fontSize="md">
            Welcome to the Ewe app ("we," "our," or "us"). Protecting your
            privacy is our priority. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you use
            the Ewe app, an online platform for learning about Yoruba herbal
            medicine and purchasing related products. By using the Ewe app, you
            consent to the practices described in this policy.
          </Text>

          {/* Section 1: Information We Collect */}
          <Heading as="h2" size="lg">
            1. Information We Collect
          </Heading>
          <Text fontSize="md">
            We collect the following types of information to provide and
            improve our services:
          </Text>
          <Box as="ul" pl={5}>
            <Text as="li">
              <b>Personal Information:</b> Name, email address, phone number,
              and shipping address when you create an account or make a
              purchase. Payment information, including credit card or PayPal
              details, processed through secure third-party payment gateways.
            </Text>
            <Text as="li">
              <b>Non-Personal Information:</b> Device and browser type, IP
              address, and app usage data for analytics. Information collected
              through cookies or similar technologies to enhance user
              experience.
            </Text>
            <Text as="li">
              <b>Community Contributions:</b> Content you post, such as
              questions, reviews, or feedback shared on the Ewe community
              platform.
            </Text>
            <Text as="li">
              <b>Location Information:</b> General location data to personalize
              the content and services available to you.
            </Text>
          </Box>

          {/* Section 2: How We Use Your Information */}
          <Heading as="h2" size="lg">
            2. How We Use Your Information
          </Heading>
          <Text>
            We use the information collected for: Providing access to our
            learning resources on Yoruba herbal medicine and spiritual
            practices. Processing orders for seeds, spices, herbs, plants, and
            other products available on the app. Improving the app’s
            functionality, user experience, and overall performance. Marketing
            and promotional activities, such as sending updates about discounts
            or new products.
          </Text>
        </VStack>
      </Box>
    </motion.div>
  );
};

export default PrivacyPolicy;
