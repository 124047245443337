import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Heading, Text, Button, Textarea, Stack, Flex, IconButton, Switch, useColorMode, Center, Spinner, Select, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react';
import { useGetStoreProductDetailsQuery, useCreateReviewMutation, useDeleteReviewMutation } from '../../api/stores'; // Adjust based on your file structure
import { FaStar, FaRegStar } from 'react-icons/fa'; // For Star Rating Icon
import Cart from '../../components/ProductCart';
import { AutoBackButton, formatCreatedAtDate, ToastNotification } from '../../components';
import { BsFillShareFill } from 'react-icons/bs';
import ShopShareButton from '../../components/ShopShareButton'; 
import { selectCurrentUser } from '../../slice/userSlice';
import { User, ProductVariant, Product, Review  } from '../../types/interface';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaTrashAlt } from 'react-icons/fa';


const StoreProductDetails = () => {
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = currentUser?.id;
  const { storeId, productId } = useParams<{ storeId: string; productId: string }>();
  const { data: product, isLoading, error } = useGetStoreProductDetailsQuery({ storeId, productId });
  const [createReview] = useCreateReviewMutation(); // Mutation hook
  const [newReview, setNewReview] = useState<{
    user: { id: string; username: string };
    rating: number;
    comment: string;
  }>({
    user: { id: '', username: '' },
    rating: 0,
    comment: '',
  });
  const [deleteReview] = useDeleteReviewMutation(); 
  const [selectedSize, setSelectedSize] = useState<string >('');
  const [reviews, setReviews] = useState<Review[]>([]);
  const { colorMode } = useColorMode();
  const [shareModalVisibility, setShareModalVisibility] = useState<any>({});
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1); 
  // console.log("Product Data: ",product);

  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const [cart, setCart] = useState<Product[]>(() => {
    // Load cart from localStorage
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // console.log("Cart: ", cart)
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    // Save cart to localStorage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    // Set default size if available
    if (product?.data?.prices?.length > 0) {
      setSelectedSize(product?.data?.prices[0].size);
    }
  }, [product]);

  const toggleCart = () => setIsCartOpen(!isCartOpen);

  const selectedVariant = product?.data?.prices.find(
    (price: any) => price.size === selectedSize
  );
 

  const addToCart = () => {
    if (!selectedSize) {
      if (!product?.data?.naira_price || !product?.data?.dollar_price) {
        setToastConfig({
          description: 'No size selected, and no fallback price available.',
          status: 'error',
        });
        setShowToast(true);
        return;
      }

      // setCart([
      //   ...cart,
      //   {
      //     ...product?.data,
      //     prices: [{ price_naira: product?.data?.naira_price, price_dollar: product?.data?.dollar_price }],
      //     quantity: selectedQuantity,
      //     store: {
      //       _id: product?.data?.store?._id,
      //       name: product?.data?.store?.name,
      //       phoneNumber: product?.data?.store?.phoneNumber,
      //     },
      //   },
      // ]);
       
      setCart([
        ...cart,
        {
          ...product?.data,
          prices: selectedVariant
            ? [selectedVariant]
            : [{ price_naira: product?.data?.naira_price, price_dollar: product?.data?.dollar_price }],
          quantity: selectedQuantity,
          store: {
            _id: product?.data?.store?._id,
            name: product?.data?.store?.name,
            phoneNumber: product?.data?.store?.phoneNumber,
          },
        },
      ]);
      

      setToastConfig({
        description: `${product?.data?.name} is added to cart successfully!`,
        status: 'success',
      });
      setShowToast(true);
      return;
    }


    if (!selectedVariant) {
      setToastConfig({
        description: 'Selected size is not available.',
        status: 'error',
      });
      setShowToast(true);
      return;
    }

    const existingProductIndex = cart.findIndex(
      (p) =>
        p._id === product?.data?._id &&
        (p.prices?.some((variant) => variant.size === selectedSize) || !selectedVariant)
    );

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += selectedQuantity;
      setCart(updatedCart);
    } else {
      setCart([
        ...cart,
        {
          ...product?.data,
          prices: [selectedVariant],
          quantity: selectedQuantity,
          store: {
            _id: product?.data?.store?._id,
            name: product?.data?.store?.name,
            phoneNumber: product?.data?.store?.phoneNumber,
          },
        },
      ]);
    }

    setToastConfig({
      description: `${product?.data?.name} (${selectedSize}) added to cart successfully!`,
      status: 'success',
    });
    setShowToast(true);
  };

  const getPriceInCurrency = (
    price: Product | ProductVariant | number, // Allow numbers for totals
    showDollar: boolean
  ) => {
    if (typeof price === 'number') {
      return showDollar ? `${price} $` : `${price} ₦`; // Handle totals
    }
  
    if (!price || typeof price !== 'object') return 'N/A'; // Handle undefined or invalid price gracefully
  
    // Check if the type is ProductVariant
    if ('price_dollar' in price && 'price_naira' in price) {
      return showDollar ? `${price.price_dollar} $` : `${price.price_naira} ₦`;
    }
  
    // Handle Product type
    if ('dollar_price' in price && 'naira_price' in price) {
      return showDollar ? `${price.dollar_price} $` : `${price.naira_price} ₦`;
    }
  
    return 'N/A'; // Fallback if none match
  };

  
  const [showDollar, setShowDollar] = useState(true); 

  useEffect(() => {
    if (product?.data) {
      setReviews(product?.data?.review || []);
    }
  }, [product]);


  const toggleShareModal = (productId: string) => {
    setShareModalVisibility((prevState: any) => ({
      ...prevState,
      [productId]: !prevState[productId], // Toggle the modal visibility for the product
    }));
  };

  const handleReviewSubmit = async () => {
    try {
      if (!newReview.comment || !newReview.rating) {
        setToastConfig({
          description: 'Please fill in all fields before submitting your review.',
          status: 'warning',
        });
        setShowToast(true);
        return;
      }
  
      const response = await createReview({
        storeId,
        productId,
        body: {
          rating: newReview.rating,
          comment: newReview.comment,
        },
      }).unwrap();
  
      // console.log('Review created successfully:', response);

      // Add new review to the reviews list
      setReviews([...reviews, response]);
  
      // Clear form
      setNewReview({
        user: { id: '', username: '' },
    rating: 0,
    comment: '',
      });
  
      // Display success toast
      setToastConfig({
        description: 'Review submitted successfully!',
        status: 'success',
      });
      setShowToast(true);
  
      // console.log('Review Response:', response);
    } catch (error) {
      console.error('Failed to submit review:', error);
  
      // Display error toast
      setToastConfig({
        description: 'Error submitting your review. Please try again.',
        status: 'error',
      });
      setShowToast(true);
    }
  };
  
const handleDeleteReview = async (productRatingId: string) => {
    try {
       await deleteReview({ storeId, productId, productRatingId }).unwrap();

        // console.log("Delete Response: ", response);
        
      setReviews(reviews.filter((review) => review._id !== productRatingId)); // Update local state after deletion
      setToastConfig({
        description: 'Review deleted successfully!',
        status: 'success',
      });
      setShowToast(true);
    } catch (error) {
      console.error('Failed to delete review:', error);
      setToastConfig({
        description: 'Error deleting the review. Please try again.',
        status: 'error',
      });
      setShowToast(true);
    }
  };
  
  if (isLoading) {
    return (
      <Center h="100vh" flexDirection="column">
        <Spinner size="lg" color="teal.500" mb={4} />
        <Text fontSize="xl" color="teal.600">Loading...</Text>
      </Center>
    );
  }
  
  if (error) return <Text>Error loading product details</Text>;

  return (
    <Box p="4" maxW="1200px" >
     <AutoBackButton />
      <Heading size="xl" mb="6" fontFamily={'Inter'} textAlign="center">{product?.data?.name}</Heading>
  
      <Stack direction={['column', 'row']} spacing="6">
        
        {/* Product Images */}
              <Box 
        flex="1" 
        display="flex" 
        flexWrap="wrap" 
        gap="4" 
        justifyContent="center"
      >
        {product?.data?.images.map((img: string, index: number) => (
          <Image
            key={index}
            src={img}
            alt={`Product Image ${index + 1}`}
            flexBasis="calc(50% - 8px)" // Two images side by side with gap
            boxSize="auto" // Allow the image to maintain aspect ratio
            maxWidth="calc(50% - 8px)" // Ensures responsiveness
            objectFit="cover"
            borderRadius="lg"
            boxShadow="md"
          />
        ))}
      </Box>


        {/* Product Details */}
        <Box flex="2" maxW="500px" fontFamily={'Inter'}>
        <Text
                paddingBottom="5px"
                textAlign="start"
                fontFamily="Inter"
                fontWeight={600}
                fontSize={16}
              >
                {formatCreatedAtDate(product?.data?.createdAt)}
              </Text>
              <Text
                             paddingBottom="5px"
                             textAlign="start"
                             fontFamily="Inter"
                             fontSize={12}
                                       >
                              <strong>Product Category:</strong> { product?.data?.category ?? 'N/A'}
                          </Text>
          <Text           
         whiteSpace="pre-wrap"
          mb="4">{product?.data?.description}</Text>
        {/* Price with Toggle */}
   <Box display="flex" alignItems="center" mb="4">
            <Text fontWeight="bold" fontSize="xl" color="teal.600" mr="4">
              Price:
            </Text>
            <Text fontSize="xl">
              {getPriceInCurrency(product?.data, showDollar)}
            </Text>
            <Switch
              ml="4"
              isChecked={showDollar}
              onChange={() => setShowDollar(!showDollar)}
            />
            <Text ml="2" fontSize="sm" color={colorMode === 'light' ? '#345430' : 'white'}>
              {showDollar ? 'USD' : 'NGN'}
            </Text>
          </Box>
          <Box mb="4">
              <Heading size="md" mb="2">Choose Quantity</Heading>
              <NumberInput
                min={1}
                value={selectedQuantity}
                onChange={(value) => setSelectedQuantity(Math.max(1, parseInt(value)))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
          </Box>

       <Box mb="4">
        
            <Heading size="md" mb="2">Choose Size</Heading>
            <Select
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              {product?.data?.prices?.map((price: { size: string}, index: any) => (
                <option key={index} value={price.size}>
                  {price.size}
                </option>
              ))}
            </Select>
          </Box>

          <Box>
            <Text fontWeight="bold">Price:</Text>
            {selectedSize ? (
              <Text>
                {getPriceInCurrency(
                  product?.data?.prices.find(
                    (price: { size: string; }) => price.size === selectedSize
                  ),
                  showDollar
                )}
              </Text>
            ) : (
              <Text>
                {getPriceInCurrency(product?.data?.prices[0], showDollar)}
              </Text>
            )}
          </Box>
          <Text
            paddingTop="5px"
            textAlign="start"
            fontFamily="Inter"
            fontSize={12}
          >
            {product?.data?.updatedAt && product?.data?.updatedAt !== product?.data?.createdAt && (
            <>
              {formatCreatedAtDate(product?.data?.updatedAt)} <span>(Edited)</span>
            </>
          )}
          </Text>

          <Button colorScheme="teal" mt="6" w="full" onClick={addToCart}>Add to Cart</Button>

          <Box display="flex" flexDirection={'row' } justifyContent="space-between" alignItems="center" mt={4}>
          <Button onClick={toggleCart}>
              {isCartOpen ? 'Close Cart' : 'Open Cart'}
            </Button>

            <Box
                      mt={5}
                      mb={5}
                      flex={['1', '1', 'auto']}
                      position="relative"
                      display="flex"
                      justifyContent={'flex-end'}
                    >
                      <Button
                        variant="ghost"
                        color={colorMode === 'light' ? 'white' : '#345430'}
                        backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                        onClick={() => toggleShareModal(`${product?.data?._id}`)}
                        // w="100%"
                      >
                        <BsFillShareFill />
                      </Button>

                      {shareModalVisibility[product?.data?._id] &&  
                        <ShopShareButton
                          productUrl={`https://www.ewe.com.ng/user/${validUserId}/stores/${storeId}/products/${product?.data?._id}`}
                          onClose={() => toggleShareModal(product._id)}
                        />
                      }
                    </Box>
                    </Box>

        </Box>
      </Stack>

          {/* {isCartOpen && (
          <Cart 
          setCart={setCart} 
          cart={cart} 
          isOpen={isCartOpen}  
          toggleCart={toggleCart} 
          language="en" 
          getPriceInCurrency={(price: ProductVariant) => getPriceInCurrency(price, showDollar)}
        />
          )} */}
          {isCartOpen && (
            <Cart
              setCart={setCart}
              cart={cart}
              isOpen={isCartOpen}
              toggleCart={toggleCart}
              language="en"
              showDollar={showDollar}
              getPriceInCurrency={(price: ProductVariant) => getPriceInCurrency(price, showDollar)}
              currentUser={currentUser}
            />
          )}


      {/* Product Reviews */}
      <Box mt="8">
        <Heading size="lg" mb="6">Reviews</Heading>
        <Stack  p={5} spacing={6}>
          {reviews.length > 0 ? (
            reviews.map((review, index) => (
              <Box
                key={index}
                p={6}
                borderWidth="1px"
                borderRadius="lg"
                boxShadow="sm"
                style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', color: colorMode === 'light' ? '#345430' : 'white' }}
                _hover={{ boxShadow: "md", transform: "scale(1.02)" }}
                transition="all 0.2s ease-in-out"
                 position="relative"
              >
                <Stack direction="row" alignItems="center" spacing={4}>
                  <Box
                    bg="gray.100"
                    w={10}
                    h={10}
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="bold"
                    color="#345430"
                  >
                    {review?.user?.username?.charAt(0).toUpperCase()}
                  </Box>
                  <Box>
                    <Link to={`/user/${review?.user?._id}`}>
                      <Text fontWeight="bold" fontSize="lg" color="blue.500">
                        @{review?.user?.username}
                      </Text>
                    </Link>
                    <StarRating rating={review?.rating} />
                  </Box>
                </Stack>
                <Text mt={4} fontSize="md">
                  {review?.comment}
                </Text>
                {review?.user?._id === currentUser.id && (
                  <IconButton
                    icon={<FaTrashAlt />}
                    // colorScheme="red"
                    size="md"
                    color={'red'}
                    aria-label="Delete Review"
                    position="absolute"
                    top={4}
                    right={4}
                    onClick={() => handleDeleteReview(review._id)}
                    _hover={{ bg: "red.600", transform: "scale(1.1)" }}
                  />
                )}
              </Box>
            ))
          ) : (
            <Text fontSize="lg" color="gray.500" textAlign="center">
              No reviews yet.
            </Text>
          )}
        </Stack>


        {/* Add Review Form */}
        <Box mt="6" p="4" borderWidth="1px" borderRadius="lg" boxShadow="sm">
        <Heading size="md" mb="4">Add Your Review</Heading>
        <Stack spacing="4">
          <StarRating rating={newReview.rating} onChange={(rating) => setNewReview({ ...newReview, rating })} />
          <Textarea
            placeholder="Your review..."
            value={newReview.comment}
            onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
          />
          <Button colorScheme="teal" onClick={handleReviewSubmit}>
            Submit Review
          </Button>
        </Stack>
      </Box>
      </Box>
      {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} />}
    </Box>
  );
};

const StarRating = ({ rating, onChange }: { rating: number; onChange?: (rating: number) => void }) => {
  const stars = Array(5).fill(false).map((_, index) => index < rating);
  return (
    <Flex direction="row" gap="1">
      {stars.map((filled, index) => (
        <IconButton
          key={index}
          icon={filled ? <FaStar /> : <FaRegStar />}
          onClick={() => onChange && onChange(index + 1)}
          variant="link"
          colorScheme={filled ? 'yellow' : 'gray'}
          size="lg"
          aria-label={`Star ${index + 1}`}
        />
      ))}

    </Flex>
  );
};

export default StoreProductDetails;
