import React, { useState } from 'react';
import { Box, Button, Container, Heading, List, ListItem, Text, Input, useColorMode, FormLabel } from '@chakra-ui/react';
import ToastNotification from './ToastNotification';
import { Product } from '../data/productData';
import { User } from '../types/interface';
import { selectCurrentUser } from '../slice/userSlice';
import { useSelector } from 'react-redux';
// import { useCreateOrderMutation } from '../api/postApi';

interface CartProps {
  cart: Product[];
  isOpen: boolean;
  toggleCart: () => void;
  language: string;
}

const Cart: React.FC<CartProps> = ({ cart, isOpen, toggleCart, language }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<'$' | '₦'>('$');
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const { colorMode } = useColorMode();

  // const [createOrder] = useCreateOrderMutation();

  // console.log("Phone  Number :", currentUser.phoneNumber)

  const toggleCurrency = () => {
    setSelectedCurrency((prevCurrency) => (prevCurrency === '$' ? '₦' : '$'));
  };

  const getPriceInCurrency = (product: Product) => {
    const { prices } = product;
  
    if (!prices) return 'Prices not available';
  
    if (selectedCurrency === '₦' && prices.naira) {
      return `${prices.naira.toLocaleString()} ${selectedCurrency}`;
    } else if (selectedCurrency === '$' && prices.dollar) {
      return `${prices.dollar.toLocaleString()} ${selectedCurrency}`;
    }
  
    return 'Prices not available';
  };
  

  const total: number = cart.reduce((runningTotal, product) => {
    const price = selectedCurrency === '$'
      ? parseFloat(product.prices.dollar.toString())
      : parseFloat(product.prices.naira.toString());

    return runningTotal + price * product.quantity;
  }, 0);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const phoneNumber = currentUser.phoneNumber; // Replace with the recipient's WhatsApp number
      const message = encodeURIComponent(
        `Order from ${name}\n` +
        `Phone Number: ${phoneNumber}\n` +
        `Email: ${email}\n` +
        `Address: ${address}\n` +
        `Total: ${total} ${selectedCurrency}\n` +
        `Items:\n${cart.map(product => `${product.name} - Quantity: ${product.quantity}`).join('\n')}`
      );
      const whatsappURI = `https://wa.me/${phoneNumber}?text=${message}`;
      const response = window.open(whatsappURI, '_blank');

      if (response) {
        setToastConfig({
          description: language === 'en'
            ? 'Success! Order was successfully sent!'
            : language === 'yo'
              ? 'Aṣeyọri! Ifiranṣẹ naa ti fi ranṣẹ ni ifijišẹ!'
              : '¡Éxito! ¡La orden se envió con éxito!',
          status: 'success',
        });
      } else {
        setToastConfig({
          description: language === 'en'
            ? 'Error! Failed to send the order.'
            : language === 'yo'
              ? 'Aṣiṣe! Ifiranṣẹ naa kuna lati fi ranṣẹ.'
              : '¡Error! Error al enviar la orden.',
          status: 'error',
        });
      }

      setShowToast(true);
    } catch (error) {
      setToastConfig({
        description: language === 'en'
          ? 'Error! Failed to send the order.'
          : language === 'yo'
            ? 'Aṣiṣe! Ifiranṣẹ naa kuna lati fi ranṣẹ.'
            : '¡Error! Error al enviar la orden.',
        status: 'error',
      });
      setShowToast(true);
    }
  };

  return (
    <Container textAlign={'center'} lineHeight={'2'}>
      <Box className={`cart ${isOpen ? 'open' : ''}`} mt={6}>
        <Heading textAlign={'start'}>
          {language === 'en' ? 'Your Cart' : language === 'yo' ? 'Cart Rẹ' : 'Tu Carrito'}
        </Heading>
        <List>
          {cart.map((product) => (
            <ListItem key={product._id} fontSize={20} padding={5}>
              {product.name} - {getPriceInCurrency(product)} - {language === 'en' ? 'Quantity' : language === 'yo' ? 'Ọpọ̀' : 'Cantidad'}: {product.quantity}
            </ListItem>
          ))}
        </List>
        <Text>
          {language === 'en' ? 'Total' : language === 'yo' ? 'Lapapọ' : 'Total'}: {total} {selectedCurrency.toUpperCase()}
        </Text>

        <form onSubmit={handleFormSubmit}>
          <FormLabel>
            {language === 'en' ? 'Your Name' : language === 'yo' ? 'Oruko Rẹ' : 'Tu Nombre'}
          </FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Name..."
                : language === 'yo'
                  ? 'Oruko...'
                  : 'Nombre...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <FormLabel>
            {language === 'en' ? 'Your Email' : language === 'yo' ? 'Email Rẹ' : 'Tu Correo Electrónico'}
          </FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Email..."
                : language === 'yo'
                  ? 'Email re...'
                  : 'Correo Electrónico...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <FormLabel>
            {language === 'en' ? 'Your Address' : language === 'yo' ? 'Address Rẹ' : 'Tu Dirección'}
          </FormLabel>
          <Input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Address..."
                : language === 'yo'
                  ? 'Address re...'
                  : 'Dirección...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <FormLabel>
            {language === 'en' ? 'Your Phone Number' : language === 'yo' ? 'Nọmba Foonu Rẹ' : 'Tu Número de Teléfono'}
          </FormLabel>
          <Input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Phone Number..."
                : language === 'yo'
                  ? 'Nọmba Foonu yin...'
                  : 'Número de Teléfono...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <Button
            onClick={handleFormSubmit}
            backgroundColor={colorMode === 'light' ? "#345430" : "white"}
            color={colorMode === 'light' ? 'white' : '#345430'}
            size="sm"
            marginTop={'10px'}
            mb={10}
          >
            {language === 'en' ? 'Proceed to Buy' : language === 'yo' ? 'E ba wa ra oja' : 'Proceder a Comprar'}
          </Button>
        </form>

        <Button
          onClick={toggleCart}
          backgroundColor={colorMode === 'light' ? "#345430" : "white"}
          color={colorMode === 'light' ? 'white' : '#345430'}
          size="sm"
          marginTop={'10px'}
        >
          {language === 'en' ? 'Close Cart' : language === 'yo' ? 'Tí Cart' : 'Cerrar Carrito'}
        </Button>
        <Button ml={4} onClick={toggleCurrency}>
          {`Switch to ${selectedCurrency === '$' ? '₦' : '$'}`}
        </Button>
      </Box>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Container>
  );
};

export default Cart;