import React, { useState } from "react";
import { Avatar, Menu, MenuButton, MenuList, MenuItem, Box, Text, Button, useColorMode } from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated, selectCurrentUser, clearUser } from "../../slice/userSlice";
import { RootState, User } from "../../types/interface";
import { useNavigate } from "react-router-dom";
import ToastNotification from "../ToastNotification";
import AuthCall from "../../api/auth";
// import { FiShoppingCart } from "react-icons/fi";
import { selectStoreUserIds } from "../../slice/storeSlice";

interface CustomButtonProps {
  children: React.ReactNode;
  to: string;
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, to, onClick }) => (
  <Text
    as={Link}
    to={to}
    onClick={onClick}
    fontSize="20px"
    fontWeight="extrabold"
    fontFamily="Inter"
    padding="8px"
    borderRadius="10px"
    color="#345430"
    backgroundColor="#F7F7F9"
    cursor="pointer"
    _hover={{ backgroundColor: "#345430", color: "white" }}
  >
    {children}
  </Text>
);

interface UserMenuProps {
  closeMenu: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ closeMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User; 
  const userRole = currentUser?.role 
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
   const { useLogoutMutation } = AuthCall();
   const { colorMode } = useColorMode();

   const [logoutMutation] = useLogoutMutation();
   const language = useSelector((state: RootState) => state.language.language);
   const handleLogout = async () => {
    try {
      const response = await logoutMutation({ userId: currentUser?.id });

      if ("data" in response) {
        const responseData = response.data.data; // Access the data property directly

        setToastConfig({
          description: responseData.message || "Logout successful", // Corrected property
          status: "success",
        });

        setShowToast(true);
        dispatch(clearUser());
        navigate("/login");
      } else if ("error" in response) {
        const errorMessage =  "An error occurred during logout"; 
        setToastConfig({ description: errorMessage, status: "error" });
        setShowToast(true);
      }
    } catch (error: any) {
      setToastConfig({ description: error.message || "An error occurred during logout", status: "error" });
      setShowToast(true);
    }
  };


  const canCreateArticle = () => {
    return userRole === 'SPECIALIST';
  };

  const storeUserIds = useSelector(selectStoreUserIds);

  // Check if currentUser.id is in the storeUserIds
  const canCreateStore = !storeUserIds.includes(currentUser?.id);


  return (
    <Menu>
      {isAuthenticated && (
        <MenuButton
          as={Button}
          display="flex"
          alignItems="center"
          variant="link"
          rightIcon={<MdArrowDropDown fontSize={32}  color={colorMode === 'light' ? '#345430' : 'white'}  />}
          cursor="pointer"
        >
          <Avatar name={currentUser?.email} style={{background: colorMode === "light" ? "#345430" : "#fff", color: colorMode === 'light' ? 'white' : '#345430'}}/>
        </MenuButton>
      )}
    <MenuList
        bg={colorMode === 'light' ? 'white' : '#345430'}
        style={{ zIndex: 999, pointerEvents: 'auto' }}
        _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }}
        _active={{ color: colorMode === 'light' ? '#588552' : '#345430' }} // Style for active page
      >
        {isAuthenticated && (
          <>
            <MenuItem
              as={Link}
              onClick={closeMenu}
              to={`/user/${currentUser?.id}`}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }} // Style for hover
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }} // Style for active page
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }} // Style for focus
            >
              Profile
            </MenuItem>
            <MenuItem
              as={Link}
              to={`/user/${currentUser?.id}/bookmarks`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Bookmarks
            </MenuItem>
            <MenuItem
              as={Link}
              to={`user/${currentUser.id}/market`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
                 {language === 'en' ? 'Stores ' : language === 'yo' ? 'Ọjà ' : 'Tienda'}
            </MenuItem>
            {canCreateArticle() && (canCreateStore === false) && (
              <MenuItem
                as={Link}
                to={`user/${currentUser.id}/create-store`}
                onClick={closeMenu}
                bg={colorMode === 'light' ? 'white' : '#345430'}
                color={colorMode === 'light' ? '#345430' : 'white'}
                _hover={{ bg: '#E0EAE0' }}
                _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
                _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
              >
                {language === 'en' ? 'Create Store' : language === 'yo' ? 'Sí Ọjà' : 'Crear Tienda'}
              </MenuItem>
            )}
             {/* <MenuItem
              as={Link}
              to={`/user/${currentUser.id}/cart`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              <FiShoppingCart/>
            </MenuItem> */}
            <MenuItem
              as={Link}
              to={`/user/${currentUser?.id}/create-post`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Upload Post
            </MenuItem>
            <MenuItem
              as={Link}
              to={`user/${currentUser?.id}/posts`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Posts/Questions
            </MenuItem>
            <MenuItem
              as={Link}
              to={`user/${currentUser?.id}/articles`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Publications 
            </MenuItem>
            {canCreateArticle() && (
            <MenuItem
              as={Link}
              to={`/user/${currentUser?.id}/create-article`}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Upload Publication
            </MenuItem>
            )}
            <MenuItem
              as={Link}
              onClick={closeMenu}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              to={`/user/${currentUser?.id}/settings`}
              _hover={{ bg: colorMode === 'light' ? '#588552' : '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Account Settings
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              bg={colorMode === 'light' ? 'white' : '#345430'}
              color={colorMode === 'light' ? '#345430' : 'white'}
              _hover={{ bg: '#E0EAE0' }}
              _active={{ color: colorMode === 'light' ? '#588552' : '#588552' }}
              _focus={{ outline: 'none', boxShadow: '0 0 2px 2px #E0EAE0' }}
            >
              Logout
            </MenuItem>
          </>
        )}
      </MenuList>
      {!isAuthenticated && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap="5px"
          alignItems="center"
          textAlign="center"
        >
          <CustomButton to="/login" onClick={closeMenu}>
            Login
          </CustomButton>
          <CustomButton to="/signup" onClick={closeMenu}>
            Sign Up
          </CustomButton>
        </Box>
      )}
       {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Menu>
  );
};

export default UserMenu;