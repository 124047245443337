import React from 'react';
import { VStack, Text, Divider, useColorMode,  Link as ChakraLink } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Socials from './Socials';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear(); 
  const { colorMode } = useColorMode();

  return (
    <motion.footer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }} // Animate from bottom to top
      transition={{ duration: 0.5 }}
    >
      <VStack spacing={6} py={10} bgColor={colorMode === 'light' ? '#fafafa' :  '#588552'}>
      <Socials />
        <Divider />
        <Text fontSize="sm" color={colorMode === 'light' ? '#345430' :  'white'} fontFamily='Inter'>
           Copyright © {currentYear}. ewe.com.ng
        </Text>
        <ChakraLink as={Link} to="/privacy-policy" fontSize="sm"  color={colorMode === 'light' ? '#345430' :  'white'}>
          Privacy Policy
        </ChakraLink>
      </VStack>
    </motion.footer>
  );
};

export default Footer;
