import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Box,  Text, Skeleton, useColorMode, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { RootState, Articles, User } from '../../types/interface';
import { ArticleList } from '..';
import { selectCurrentUser } from '../../slice/userSlice';
import { useLazyGetUserArticleQuery } from '../../api/articles';

interface UserArticleProps {
  userId: string ;
}

const UserArticle: React.FC<UserArticleProps> = ({ userId }) => {
  const isAuthenticated = useSelector((state: RootState) => state.users.isLogin);
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const language = useSelector((state: RootState) => state.language.language);
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const [triggerUserArticles, { data: article, isLoading: articleLoading }] = useLazyGetUserArticleQuery();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (userId) {
      triggerUserArticles(userId, true);
    }
  }, [userId, triggerUserArticles]);

  const [articles, setArticles] = useState<Articles[]>([]);


  

  useEffect(() => {
    if (article) setArticles(article?.data?.articles || []);
  }, [article]);


  const redirectToCreateArticle = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-article`;
      navigate(createPostUrl);
    } else {
      navigate("/login");
    }
  };


  const updatedArticleData = (articleId: string, newData: Partial<Articles>) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article._id === articleId ? { ...article, ...newData } : article
      )
    );
  };
  
  const handleArticleClick = (articleId: string) => {
    navigate(`/user/${userId}/articles/${articleId}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewé - Article Page</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#345430',
          color: colorMode === 'light' ? '#345430' : 'white',
        }}
      >
        <Box
          paddingTop="20px"
          paddingBottom="20px"
          marginRight={{ base: "15", md: "20" }}
          marginLeft={{ base: "15", md: "20" }}
          bg={colorMode === 'light' ? 'white' : '#345430'}
          color={colorMode === 'light' ? '#345430' : 'white'}
          fontFamily="Inter"
        >
          {articleLoading ? (
            <Box>
              {[1, 2, 3].map((item) => (
                <Box
                  key={item}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  mb={4}
                  padding={10}
                  display="flex"
                  flexDirection={{ base: 'column', sm: 'row' }}
                  gap={10}
                >
                  <Skeleton height="200px" width="30%" mr={4} />
                  <Box flexBasis="70%">
                    <Skeleton height="30px" mb={2} />
                    <Skeleton height="20px" mb={2} />
                    <Skeleton height="20px" mb={2} />
                    <Skeleton height="20px" width="50%" />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : articles?.length === 0 ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={20}
              flexDir={"column"}
            >
              <Text textAlign={'center'}>No articles available.</Text>
              {currentUser.id === userId && (
              <Button
                onClick={redirectToCreateArticle}
                mt={0}
                bg={colorMode === "light" ? "#345430" : "white"}
                padding={"20px"}
                color={colorMode === "light" ? "white" : "#345430"}
                variant="solid"
                borderRadius={"10px"}
                _hover={{
                  color: "#345430",
                  backgroundColor: "#F7F7F9",
                }}
                size="md"
                      >
                    {language === "en"
                          ? "Create articles"
                          : language === "yo"
                          ? "Fún wa ní ìwádí kan"
                          : language === "es"
                          ? "Crear artículos"
                          : "Create articles"}
              </Button>
              )}
            </Box>
          ) : (
            <ArticleList
              articles={articles}
              isAuthenticated={isAuthenticated}
              handleArticleClick={handleArticleClick}
              updateArticleData={updatedArticleData} 
            />
          )}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default UserArticle;
