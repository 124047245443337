import {
  Box,
  Button,
  Text,
  Container,
  useColorMode,
  Collapse,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useParams } from "react-router-dom";
import { FormInput, FormInputConfirmPassword, FormInputPassword, FormPhoneInput, FormSelectOption, useUserProfileSettings } from '../components';
import FormTextarea from '../components/Form/FormTextarea';
import { Helmet } from 'react-helmet';
import { AutoBackButton, ToastNotification} from "../components";
import { useState } from 'react';
import DeactivateDeleteAccount from '../components/DeactivateDeleteAccount';
import { selectCurrentUser } from '../slice/userSlice';
import { User } from '../types/interface';
import { useSelector } from 'react-redux';


const UserProfileSettings = () => {
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const {
    handleSubmit,
    control,
    errors,
    isValid,
    isSubmitting,
    register,
    getValues,
    onSubmit,
    trigger,
    showToast,
    toastConfig,
    setShowToast
  } = useUserProfileSettings(userId || String(currentUser?.id));

  const { colorMode } = useColorMode();
  const [showDeactivateSection, setShowDeactivateSection] = useState(false);


  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5, ease: "easeIn" }}
    style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430',
    color: colorMode === 'light' ? 'white' :  '#345430'}}   
  >
    <Container 
    justifyContent="center" 
    alignItems="center" 
    paddingTop={20}
    style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430',
    color: colorMode === 'light' ? '#345430' : 'white'}}    
    paddingBottom={20}>
      <Box p="10" bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'} 
      borderRadius={'20px'} minH="auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
        >
        <Helmet>
        <meta charSet="utf-8" />
        <title>User Settings Page</title>
      </Helmet>
      <AutoBackButton/>
          <Text fontSize="xl" textAlign="center" fontWeight="bold" mb={4}>
            User Account Settings
          </Text>
          <form style={{ width: '100%', padding: '20px' }} onSubmit={handleSubmit(onSubmit)}>
                     <FormInput
                          name="name"
                          label="Change Name"
                          control={control}
                          register={register}
                          rules={{
                              required: 'Name is required',
                          }} 
                          errors={errors}
                          onKeyUp={() => {
                             trigger("name");
                          }}
                          text=""
                          />
                     <FormInput
                          name="username"
                          label="Change Username"
                          control={control}
                          register={register}
                          rules={{
                            required: 'Username is required',
                            pattern: {
                              value: /^[A-Za-z0-9_]+$/, 
                              message: 'Username must contain only letters, numbers, and underscores',
                            },
                            minLength: {
                              value: 3,
                              message: 'Username must be at least 3 characters',
                            },
                            maxLength: {
                              value: 20,
                              message: 'Username must be less than 20 characters',
                            },
                          }}
                          onKeyUp={() => {
                            trigger("username");
                         }}
                          errors={errors}
                          text="@"
                                    />

                        <FormTextarea
                          name="about"
                          label="Add About"
                          control={control}
                          register={register}
                          rules={{
                              required: 'About is required',
                              maxLength: {
                                  value: 200,
                                  message: 'About must be less than 200 characters',
                              },
                          }}
                          errors={errors}
                          onKeyUp={() => {
                            trigger("about");
                         }}
                             />

                      <FormInputPassword
                          name="password"
                          label="Change Your Password"
                          control={control}
                          register={register}
                          rules={{
                              required: 'Password is required',
                              pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*])[A-Za-z\d.!@#$%^&*]{8,20}$/,
                                  message: 'Password must have a capital letter, a small letter, a number, and a symbol (e.g., .!@#$%^&*)',
                              },
                              minLength: {
                                  value: 8,
                                  message: 'Password must be at least 8 characters',
                              },
                              maxLength: {
                                  value: 20,
                                  message: 'Password must be less than 20 characters',
                              },
                          }}
                          errors={errors}
                                onKeyUp={() => {
                             trigger("password");
                          }}
                          />

                        <FormInputConfirmPassword
                          name="confirmPassword"
                          label="Confirm Password"
                          control={control}
                          register={register}
                          rules={{
                              required: 'Please confirm your password',
                              validate: (value: any) => value === getValues('password') || 'Passwords do not match',
                          }}
                          errors={errors}
                          onKeyUp={() => {
                            trigger("confirmPassword");
                         }}
                         />

                        <FormPhoneInput
                          name="phoneNumber"
                          label="Change Your Phone Number"
                          control={control}
                          register={register}
                          rules={{
                              pattern: {
                                  value: /^[0-9+\s()-]*$/,
                                  message: 'Invalid phone number',
                              },
                          }} 
                          errors={errors}       
                          onKeyUp={() => {
                            trigger("phoneNumber");
                         }}
                            />
                             <FormSelectOption
                            name="role"
                            label="Change Role"
                            control={control}
                            register={register}
                            rules={{ required: "Please select a Role" }}
                            errors={errors}
                            options={[
                              { value: "USER", label: "USER" },
                              { value: "SPECIALIST", label: "SPECIALIST" },
                            ]}
                            onKeyUp={() => {
                              trigger("role");
                           }}
                             />
                            <FormSelectOption
                            name="field"
                            label="Add Your Field"
                            control={control}
                            register={register}
                            rules={{ required: "Please select a field" }}
                            errors={errors}
                            options={[
                                { value: "babalawo/iyanifa", label: "Babalawo/Iyanifa" },
                                { value: "onisegun", label: "Onisegun/Herbalist" },
                                { value: "adahunse", label: "Adahunse/Researcher" },
                                { value: "dokita", label: "Dokita/Medical Doctor" },
                                { value: "nurse", label: "Nurse" },
                                { value: "pharmarcy", label: "Pharmacist" },
                                { value: "student", label: "Student" },
                                { value: "other", label: "Others..." },
                            ]}
                            onKeyUp={() => {
                              trigger("field");
                           }}
                            />
            <Button
              type="submit"
              mt={6}
              bg={colorMode === 'light' ? '#345430' : 'white'} 
              padding={'20px'}
              color={colorMode === 'light' ? 'white' :  '#345430'} 
              isLoading={isSubmitting}
              loadingText="Saving..."
              size="lg"
              width={'full'}
              disabled={!isValid}
              marginTop={'10px'}
              className="button_hover"
            >
              Save Changes
            </Button>

             {/* Other profile settings content here */}
      <Box textAlign="center" mt={5} mb={6}>
        <Button
          colorScheme="blue"
          onClick={() => setShowDeactivateSection(!showDeactivateSection)}
        >
          {showDeactivateSection
            ? 'Hide Deactivate/Delete Options'
            : 'Show Deactivate/Delete Options'}
        </Button>
      </Box>

      <Collapse in={showDeactivateSection} animateOpacity>
        <Box
          p={4}
          bg={colorMode === 'light' ? 'yellow.50' : 'gray.600'}
          borderRadius="md"
          boxShadow="sm"
        >
          <DeactivateDeleteAccount userId={userId} />
        </Box>
      </Collapse>

            {showToast && (
            <ToastNotification
              description={toastConfig.description}
              status={toastConfig.status}
              onClose={() => setShowToast(false)}
            />
          )}
          </form>
        </motion.div>
      </Box>
    </Container>
    </motion.div>
  );
};

export default UserProfileSettings;
