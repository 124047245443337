import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { BankTransfer, BitCoinTransfer, GoFundMe, Header } from '../components';
import { Helmet } from 'react-helmet';
import { VStack, Heading, Text, useColorMode, Box } from '@chakra-ui/react';
import { RootState } from '../types/interface';
import { useEffect } from 'react';

const Donate = () => {
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Donate To Us Page</title>
      </Helmet>
      <Header />
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
      <Box 
      paddingTop="20px"
      paddingBottom="20px"
      marginRight={{ base: "15", md: "20"}}
      marginLeft={{ base: "15", md: "20"}}
       style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430', 
       color: colorMode === 'light' ? '#345430' :  'white'}}
       >
        <VStack spacing={8} align="center" mt={8} paddingTop={20} paddingBottom={20}>
          <Heading
            fontFamily="Inter"
            as="h4"
            size="xl"
            textAlign="center"
            fontWeight={600}
          >
          {language === 'en' ? 'Support Our Cause' : language === 'es' ? 'Apoya Nuestra Causa' : 'Rànwálọwọ́'}
          </Heading>
          <Text fontSize="16px" 
            >
          {language === 'en' ? `Ewé extends a heartfelt invitation to our esteemed members, supporters, and well-wishers to join hands in supporting and advancing our shared goals and vision. We are on a mission to gather and evolve, focusing on user-generated content that encapsulates the wealth of indigenous cultural information about plants, herbs, animals, birds, and more.
          The profound advantages of storing this valuable data in our local language cannot be overstated. It not only ensures accurate data storage but also enhances search queries, opens up avenues for research, and lays the groundwork for future development.
          Your contribution holds the power to make a significant difference in realizing our mission. Whether you're passionate about preserving cultural knowledge or supporting sustainable practices, your involvement is invaluable. We genuinely appreciate your support in helping us achieve these goals.
          To make a meaningful contribution, please visit our donation page, where every contribution, big or small, contributes to the tapestry of Ewé's growth and impact. Alternatively, feel free to reach out to us for more information, and for those interested in offering Human Resources support, your collaboration is particularly welcomed.
          Thank you for being a vital part of our journey. Together, we can nurture and preserve the essence of our cultural heritage for generations to come.` : language === 'es' ? `Ewé invita a nuestros miembros y simpatizantes a unirse a nosotros en la preservación del conocimiento cultural indígena sobre plantas, hierbas, animales y más. Almacenar esta información en nuestro idioma nativo garantiza su conservación precisa y fomenta la investigación y el desarrollo.
          Tu apoyo es invaluable. Ya sea a través de donaciones o colaboración, cada contribución fortalece el crecimiento de Ewé. Visita nuestra página de donaciones o contáctanos para trabajar juntos en preservar este legado cultural para las futuras generaciones.` : `Ewé na ọwọ́ ìfẹ́ fi pe àwọn ẹlẹ́gbẹ́ẹ wa, àwọn alátìlẹ́yìn wa, àti àwọn olólùfẹ́ wa kí gbogbo wa fọwọ́ sọwọ́ pọ̀ láti tì wá lẹ́yìn àti láti jẹ́ ki ìtẹ̀síwájú dé bá iṣẹ́ wa kó lè dé ibi tí a pinu láti dé àti ibi tí a ri pé a lè dé. 
          Ìpinu wá ní láti ní àkójọpò ìmọ̀ ìjìnlẹ̀ ìbílẹ̀ lórí àwọn nǹkan ọ̀gbìn, ewé àti egbò, ẹranko, ẹyẹ àti bẹ́ẹ̀bẹ́ẹ̀ lọ láti lè jẹ́ kí a túbọ̀ mọ ìwúlò wọn síwájú si àti pé kí ó lè ranrùn fún wa láti wá wọn rí.
          Iye oun rere pàtàkì tó pọ̀ nínúu pé kí a máa tọ́jú àwọn ìmọ̀ ìjìnlẹ̀ yí ní ède abínibí wa ọ̀ ṣé fẹnu sọ. Bí ó tin ńjẹ́ kí a ma mọ àti to àwọn ìmọ̀ yí lẹ́ṣẹṣẹ, bẹ́ẹ̀ náà ni ó ń ṣí ọ̀nà fún ìwádìí tí ó kún régé tí ó sì ma ṣí ọ̀nà fún ìdàgbàsókè ní ọjọ́ iwájú 
          Dídá si yín á tún bọ̀ fún wa ní agbára láti jẹ́ kí a lè ṣe àṣeyọrí lórí ìpinu wa lórí iṣẹ́ yìí. Tó bá jẹ́ kí ìmọ̀ àṣà àti ìṣe wá má parun lẹ mú ní òkúnkúndùn ni tàbí ìṣe tí ò ní pa àwa àti àyíká wa lára ni ẹ̀yíń tì lẹ́yìn ni, a ò kó àtìleyìn yín kéré rárá. A dúpẹ́ fún ìgbìyànjú yín lóríi pé kí a lè ṣe àṣeyọrí àti àṣeyege.
          Láti lè tì wá lẹ́yìn síwájú si, ẹ báwa lọ sí ojú ibi tí ìdáwósíi wá wà kí ẹ báwa d'ọwọ́ bòó, àti èyí tó kéré, àti èyí tí ó pọ̀, a ó ma fi ṣe ìtẹ̀síwájú fún Ewé náà ni.
          Ọ̀nà míràn tí ẹ tún lè gbà rà wá lọ́wọ́ ni pé ẹ lè kàn sí wa tí ó bá jẹ́ iṣẹ́ ṣíṣe lẹ fẹ́ fi tì wá lẹ́yìn, ìyẹn gan tún ṣe pàtàkì lọ́tọ̀. 
          Ẹṣeun tí ẹ dara pọ̀ mọ́ wa láti tẹ síwájú nínú irin àjò wá yi, pẹ̀lú ìfọwọ́ sọwọ́ pọ̀ gbogbo wa, à á lè mójú tó ìdàgbàsókè àṣà àti àjogúnbá wá tí a ó sì rí gbé kalè fún àwọn ọjọ́ ọ̀la wa.`}
          </Text>
          <VStack spacing={10} align="start">
            {/* <FlutterTransfer/>  */}
            <BankTransfer />
            <BitCoinTransfer/>
            <GoFundMe />
          </VStack>
        </VStack>
      </Box>
      </motion.div>
    </motion.div>
  );
}
  export default Donate;

  