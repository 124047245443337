import React, { useEffect, useMemo, useState } from 'react';
import { Box, Image, Text, Heading, 
  Grid, 
  Button, 
  Input, 
  useColorMode, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  Spinner, 
  Flex,
  Switch } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import { useDeleteProductMutation, useGetStoreProductQuery } from '../../api/stores';
import { Product, RootState, User, ProductVariant } from '../../types/interface';
import { BsFillShareFill } from 'react-icons/bs';
import ShopShareButton from '../../components/ShopShareButton'; // Assuming this is a custom component
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../slice/userSlice';
import { formatCreatedAtDate, ToastNotification } from '../../components';
import { FiEdit } from 'react-icons/fi';

enum ItemCategory {
  OIL = 'Oils',
  SOAP = 'Soaps',
  HERBAL_MEDICINE = 'Herbal Medicines',
  SPICE = 'Spices',
  HERBS = 'Herbs',
  SEED = 'Seeds',
  SPIRITUAL_ITEMS = 'Spiritual Items',
  HERBAL_TEA = 'Herbal Teas',
  CANDLES = 'Candles',
  BEADS = 'Beads',
}

const PRODUCTS_PER_PAGE = 20;

type StoreProductsProps = {
  storeId?: string;
  storeUserId?: string;
};

const StoreProducts: React.FC<StoreProductsProps> = ({ storeId: propStoreId, storeUserId }) => {
  const { colorMode } = useColorMode();
  const { storeId: paramStoreId } = useParams<{ storeId: string }>();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;
  const storeId = propStoreId || paramStoreId;
  const { data, isLoading, isError } = useGetStoreProductQuery(storeId);
  const [products, setProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [shareModalVisibility, setShareModalVisibility] = useState<any>({}); // Initialize the modal visibility state
  const language = useSelector((state: RootState) => state.language.language);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const [selectedSize, setSelectedSize] = useState<string >('');
  const [expandedStates, setExpandedStates] = useState<Record<string, boolean>>({});

  const toggleExpand = (productId: string) => {
    setExpandedStates((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const [deleteProduct] = useDeleteProductMutation();

const handleDeleteProduct = async (productId: string) => {
  try {
    await deleteProduct({ storeId, productId }).unwrap();
    // console.log('Product deleted successfully:', response);

    // Update the local state to remove the deleted product
    setProducts(products.filter(product => product._id !== productId));

    // Display success toast
    setToastConfig({
      description: "Product deleted successfully!",
      status: "success",
    });
    setShowToast(true);
  } catch (error) {
    console.error('Error deleting product:', error);

    // Display error toast
    setToastConfig({
      description: "Failed to delete product. Please try again.",
      status: "error",
    });
    setShowToast(true);
  }
};


const navigate = useNavigate();

const handleProductClick = (product: Product) => {
  setSelectedSize(product?.prices?.[0]?.size || 'Default Size');
  navigate(`/user/${validUserId}/stores/${storeId}/products/${product?._id}`);
};

  useEffect(() => {
    if (data?.data) {
      setProducts(data.data);
    }
  }, [data]);


  // console.log("Products: ", products);

  const toggleCurrency = () => {
    setShowDollar((prevShowDollar) => !prevShowDollar);
  };
  

  useEffect(() => {
    if (products?.length > 0 && products[0]?.prices?.length > 0) {
      setSelectedSize(products[0]?.prices?.[0]?.size || 'Default Size');
    }
  }, [products]);
  

  
  const getPriceInCurrency = (
    price: Product | ProductVariant | number, // Allow numbers for totals
    showDollar: boolean
  ) => {
    if (typeof price === 'number') {
      return showDollar ? `${price} $` : `${price} ₦`; // Handle totals
    }
  
    if (!price) return showDollar ? 'N/A $' : 'N/A ₦';
  
    // Check if the type is ProductVariant
    if ('price_dollar' in price && 'price_naira' in price) {
      return showDollar ? `${price.price_dollar} $` : `${price.price_naira} ₦`;
    }
  
    // Handle Product type
    if ('dollar_price' in price && 'naira_price' in price) {
      return showDollar ? `${price.dollar_price} $` : `${price.naira_price} ₦`;
    }
  
    return 'N/A'; // Fallback if none match
  };
  
  const [showDollar, setShowDollar] = useState(true); 


  const toggleShareModal = (productId: string) => {
    setShareModalVisibility((prevState: any) => ({
      ...prevState,
      [productId]: !prevState[productId], // Toggle the modal visibility for the product
    }));
  };

  const handleCategorySelect = (category: string) => setSelectedCategory(category);

  const filteredProducts = products.filter(product =>
    (selectedCategory === 'All' || product.category === selectedCategory) &&
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE);
  const currentProducts = useMemo(() => {
    const filtered = products.filter((product) => 
      (selectedCategory === 'All' || product.category === selectedCategory) &&
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filtered.slice((currentPage - 1) * PRODUCTS_PER_PAGE, currentPage * PRODUCTS_PER_PAGE);
  }, [products, searchTerm, selectedCategory, currentPage]);
  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Box  m={{ base: '5px', md: '20px' }}   >
        <Heading  fontSize={{ base: 16, sm: 20, md: 25 }}   mb={4}>Products</Heading>

        <Box display="flex" flexDir={{ base: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" mb={6}>
          <Input
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={{ base: '100%', md: '60%' }}
            mb={{ base: 4, md: 0 }}
          />
            <Flex
        flexDir={"row"} // Stack on small screens
        justifyContent={{ sm: "center", md: "space-between" }} // Center or space-between
        alignItems="center"
        gap={4} // Add spacing between items
      >
           <Menu>
            <MenuButton as={Button} rightIcon={<FaChevronDown />}>
              {selectedCategory}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleCategorySelect('All')}>All</MenuItem>
              {Object.values(ItemCategory).map((category) => (
                <MenuItem key={category} onClick={() => handleCategorySelect(category)}>
                  {category}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Button
            ml={{ base: 0, md: 4 }}
            onClick={toggleCurrency}
            w={{ base: '100%', md: 'auto' }}
          >
            {`Switch to ${showDollar ? '₦' : '$'}`}
          </Button>

      </Flex>
        </Box>

        {isLoading ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">
            <Spinner />
            Loading products...
          </Box>
        ) : isError || filteredProducts?.length === 0 ? (
          <Text>No products available.</Text>
        ) : (
          <>
            <Grid 
              templateColumns="repeat(auto-fill, minmax(300px, 1fr))" 
              gap={4} // Adjust the gap for spacing between items
              px={{ base: 2, sm: 4, md: 6 }}>
              {currentProducts?.map((product) => (
                <Box
                  key={product._id}
                  borderWidth="1px"
                  borderRadius="lg" 
                  overflow="visible"
                  p="4"
                  // bg={colorMode === 'light' ? 'white' : 'gray.800'}
                  fontFamily="Inter"
                  cursor="pointer" // Optional: adds a pointer cursor on hover
                  w="100%" // Ensure the product box takes up full width of the grid cel
                  objectFit="cover"
                >
                  <Box
                   onClick={() => handleProductClick(product)}>
                    <Image
                      src={product?.images?.[0] || 'default-image-url.jpg'} 
                      // Fallback to a default image if undefined
                      alt={product?.name || 'No name'}
                      objectFit="cover"
                      borderRadius="md"
                      mb={4}
                    />
                  <Heading size="md" mb={2}>{product.name}</Heading>
                  <Text
                paddingBottom="5px"
                textAlign="start"
                fontFamily="Inter"
                // fontWeight={600}
                fontSize={12}
              >
                {formatCreatedAtDate(product?.createdAt)}
              </Text>
              <Text
                paddingBottom="5px"
                textAlign="start"
                fontFamily="Inter"
                // fontWeight={600}
                fontSize={12}
              >
                  <strong>Product Category:</strong> { product?.category ?? 'N/A'}
              </Text>
               <Box>
               <Text fontSize="sm" mb={2}>
                {product?.description?.length > 100 && !expandedStates[product?._id]
                  ? `${product?.description?.substring(0, 100)}...`
                  : product?.description}
              </Text>
              {product?.description?.length > 100 && (
                <Button
                  size="sm"
                  variant="link"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event propagation to avoid triggering other handlers.
                    toggleExpand(product._id);
                  }}
                >
                  {expandedStates[product._id] ? 'Show Less' : 'Read More'}
                </Button>
              )}

                </Box>
                <Box display="flex" alignItems="center" mb="4">
            <Text fontWeight="bold" fontSize="xl" color="teal.600" mr="4">
              Price:
            </Text>
            <Text fontSize="xl">
              {getPriceInCurrency(product, showDollar)}
            </Text>
            <Switch
              ml="4"
              isChecked={showDollar}
              onChange={toggleCurrency}
            />
            <Text ml="2" fontSize="sm" color={colorMode === 'light' ? '#345430' : 'white'}>
              {showDollar ? 'USD' : 'NGN'}
            </Text>
          </Box>
                  </Box>
         

                  <Box 
                  display="flex" 
                  flexDirection={ 'row' } 
                  justifyContent="center" 
                  alignItems="center" 
                  mt={4}
                  gap={{ base: 4, md: 5 }} // Add responsive gap spacing
                  >
                    <Button
                      fontWeight="bold"
                      py={2}
                      px={4}
                      borderRadius={20}
                      onClick={() => handleProductClick(product)}
                      w="100%"
                    >
                      {language === 'en' ? 'Add to Cart' : language === 'yo' ? 'Fí Sí Cart' : 'Añadir al carrito'}
                    </Button>
                    {storeUserId === currentUser.id  && ( // Only show the delete button if the user is the owner
                      <Button
                        fontWeight="bold"
                        colorScheme="red"
                        size="sm"
                        p={5}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteProduct(product._id);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                      {storeUserId === currentUser?.id  && ( 
                        <Button
                        fontWeight="bold"
                        colorScheme="blue"
                        size="md"
                        onClick={() => navigate(`/user/${userId}/stores/${product?.store?._id}/products/${product._id}/edit-product`)}
                      >
                          <FiEdit 
                          size={40} />
                      </Button>
                        )}
                    <Box
                      mt={5}
                      mb={5}
                      flex={['1', '1', 'auto']}
                      position="relative"
                      display="flex"
                      justifyContent={'flex-end'}
                  overflow="visible"
                    >
                      <Button
                        variant="ghost"
                        color={colorMode === 'light' ? 'white' : '#345430'}
                        backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                        onClick={() => toggleShareModal(`${product._id}`)}
                        w="100%"
                      >
                        <BsFillShareFill />
                      </Button>


                      {shareModalVisibility[product._id] &&  
                        <ShopShareButton
                          productUrl={`https://www.ewe.com.ng/user/${validUserId}/stores/${storeId}/products/${product._id}`}
                          onClose={() => toggleShareModal(product._id)}
                        />
                      }
                    </Box>
                  </Box>
                </Box>
              ))}
            </Grid>


            <Box mt={6} display="flex" justifyContent="center" alignItems="center">
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  colorScheme={currentPage === index + 1 ? 'teal' : 'gray'}
                  mx={1}
                >
                  {index + 1}
                </Button>
              ))}
            </Box>
          </>
        )}
        {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} />}
      </Box>
    </motion.div>
  );
};

export default StoreProducts;
