import React, { useState } from 'react';
import {
  Box,
  Button,
  useColorMode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  // Spinner,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserAccountMutation, useDeactivateUserAccountMutation } from '../api/userApi';

interface DeactivateDeleteProps {
  userId: any;
}

const DeactivateDeleteAccount: React.FC<DeactivateDeleteProps> = ({ userId }) => {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingAction, setLoadingAction] = useState<'deactivate' | 'delete' | null>(null);
  const toast = useToast();
  const navigate = useNavigate();

  // API Mutations
  const [deleteUser] = useDeleteUserAccountMutation();
  const [deactivateUser] = useDeactivateUserAccountMutation();

  // Deactivate Account Handler
  const handleDeactivate = async () => {
    setLoadingAction('deactivate');
    try {
      const response = await deactivateUser({ userId });
      if (response) {
        toast({
          title: 'Account Deactivated',
          description: 'Your account has been deactivated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/'); // Redirect to home
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to deactivate account. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingAction(null);
    }
  };

  // Delete Account Handler
  const handleDelete = async () => {
    setLoadingAction('delete');
    try {
      const response = await deleteUser({ userId });
      if (response) {
        toast({
          title: 'Account Deleted',
          description: 'Your account has been deleted successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/'); // Redirect to home
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete account. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingAction(null);
    }
  };

  return (
    <Box mt={8} textAlign="center" color={colorMode === 'light' ? '#345430' : 'white'}>
      <Text fontSize="lg" mb={4}>
        You can deactivate or delete your account.
      </Text>
      <Button
        colorScheme="yellow"
        mr={4}
        isLoading={loadingAction === 'deactivate'}
        onClick={() => {
          onOpen();
          setLoadingAction('deactivate');
        }}
      >
        Deactivate Account
      </Button>
      <Button
        colorScheme="red"
        isLoading={loadingAction === 'delete'}
        onClick={() => {
          onOpen();
          setLoadingAction('delete');
        }}
      >
        Delete Account
      </Button>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {loadingAction === 'deactivate' ? 'Deactivate Account' : 'Delete Account'}
          </ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to {loadingAction === 'deactivate' ? 'deactivate' : 'delete'} your account? This action
              cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={3} variant="ghost">
              Cancel
            </Button>
            <Button
              colorScheme={loadingAction === 'deactivate' ? 'yellow' : 'red'}
              onClick={loadingAction === 'deactivate' ? handleDeactivate : handleDelete}
              isLoading={loadingAction !== null}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeactivateDeleteAccount;
