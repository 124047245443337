import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  Icon,
  Flex,
  useColorMode,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';
import { BiLike, BiDislike, BiError } from 'react-icons/bi';
import { GoVerified } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../ToastNotification';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { Answer, User } from '../../types/interface';
import { useAnswerQuestionMutation, useLikeAnswerMutation, useDislikeAnswerMutation } from '../../api/questions';
import { Link } from 'react-router-dom';

interface AnswerComponentProps {
  questionId: string;
  answers: Answer[];
  updatedAnswersData: (questionId: string, updatedAnswers: Answer[]) => void; 
}
interface AnswerFormData {
  answer: string;
}

const Answers: React.FC<AnswerComponentProps> = ({ questionId, updatedAnswersData, answers }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors, isSubmitting, isValid }, reset } = useForm<AnswerFormData>({
    criteriaMode: 'all',
    defaultValues: { answer: '' },
  });
  const { colorMode } = useColorMode();
  const [answerQuestion] = useAnswerQuestionMutation();
  const [likeAnswer] = useLikeAnswerMutation();
  const [dislikeAnswer] = useDislikeAnswerMutation();

  useEffect(() => {
    if (answers) {
      updatedAnswersData(questionId, answers.map((answer: Answer) => answer));
    }
  }, [questionId, answers, updatedAnswersData]);

  const handleLike = async (answerId: string) => {
    if (!isAuthenticated) {
      return navigate("/login");
    }
    try {
      await likeAnswer({ userId: currentUser.id, questionId, answersId: answerId }).unwrap();
      setToastConfig({ description: 'Answer liked successfully', status: 'success' });
      setShowToast(true);
    } catch (error) {
      console.error('Error liking answer:', error);
      setToastConfig({ description: 'Failed to like answer', status: 'error' });
      setShowToast(true);
    }
  };

  const handleDislike = async (answerId: string) => {
    if (!isAuthenticated) {
      return navigate("/login");
    }
    try {
      await dislikeAnswer({ userId: currentUser.id, questionId, answersId: answerId }).unwrap();
      setToastConfig({ description: 'Answer disliked successfully', status: 'success' });
      setShowToast(true);
    } catch (error) {
      console.error('Error disliking answer:', error);
      setToastConfig({ description: 'Failed to dislike answer', status: 'error' });
      setShowToast(true);
    }
  };

  const onSubmit: SubmitHandler<AnswerFormData> = async (formData) => {
    if (!isAuthenticated) {
      return navigate("/login");
    }
    try {
      await answerQuestion({ userId: currentUser?.id, questionId: questionId, comment: formData }).unwrap();
      setToastConfig({ description: 'Answer added successfully', status: 'success' });
      setShowToast(true);
      reset();
      window.location.reload(); 
    } catch (error) {
      console.error('Error submitting answer:', error);
      setToastConfig({ description: 'Failed to submit answer', status: 'error' });
      setShowToast(true);
    }
  };

  return (
    <Box>
      {showToast && <ToastNotification {...toastConfig} />}
      <VStack align="start" spacing={4} mt={4} mb={5}>
        {answers?.map((answer: any) => (
          <Box key={answer._id} width="100%">
                  <Link to={`/user/${answer?.user?._id}`}>
            <Text fontWeight="bold"
                        fontFamily="Inter"
                        fontSize={{ base: "16px", md: "18px", lg: "20px" }}
                         color={colorMode === 'light' ? "#345430" : 'white'}>@{answer.user.username}</Text>
                         </Link>
            <Text
            color={colorMode === 'light' ? "#345430" : 'white'}
            fontFamily="Inter"
            fontSize={{ base: "14px", md: "16px", lg: "18px" }}
        whiteSpace="pre-wrap"
         className="no-select"
         >{answer?.answer}</Text>
            <Flex mt={2} align="center" justify="start">
              <Flex align="center">
                <Button onClick={() => handleLike(answer?._id)} size="sm" variant="ghost" leftIcon={<Icon as={BiLike} />}>
                  {answer?.likes?.length}
                </Button>
                <Button onClick={() => handleDislike(answer?._id)} size="sm" variant="ghost" leftIcon={<Icon as={BiDislike} />}>
                  {answer?.dislikes?.length}
                </Button>
              </Flex>
              <Flex align="center">
                {answer?.verify ? (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <GoVerified style={{
                      color: colorMode === 'light' ? '#345430' : 'white',
                      backgroundColor: colorMode === 'light' ? 'white' : '#345430'
                    }} fontSize={20} />
                    <Text
                      style={{ marginLeft: '8px', color: colorMode === 'light' ? '#345430' : 'white' }}
                      fontSize={'10'}
                    >
                      Verified
                    </Text>
                  </Box>
                ) : (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <BiError color="red" fontSize={20} />
                    <Text
                      style={{ marginLeft: '8px', color: colorMode === 'light' ? '#345430' : 'white' }}
                      fontSize={'10'}
                    >
                      Not Verified
                    </Text>
                  </Box>
                )}
              </Flex>
            </Flex>
          </Box>
        ))}
      </VStack>

      {isAuthenticated && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors?.answer} isRequired>
            <FormLabel 
              style={{ color: colorMode === 'light' ? '#345430' : 'white' }}
              htmlFor="answer">Your Answer</FormLabel>
            <Textarea
              id="answer"
              placeholder="Type your answer..."
              {...register("answer", {
                required: "This field is required",
                minLength: {
                  value: 10,
                  message: "Answer must be at least 10 characters",
                },
                maxLength: {
                   value: 300,
                   message: "Answer must not be more than 3000 characters",
                }
              })}
              h={300}
              color={colorMode === 'light' ? '#345430' : 'white'}
            />
            <FormErrorMessage>
              {errors?.answer && errors?.answer?.message}
            </FormErrorMessage>
          </FormControl>
          
          <Button
            type="submit"
            mt={4}
            backgroundColor={colorMode === 'light' ? "#345430" : "white"}
            color={colorMode === 'light' ? 'white' : '#345430'}
            size="lg"
            width={'full'}
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting}
          >
            Submit Answer
          </Button>
        </form>
      )}
    </Box>
  );
};

export default Answers;
