import React from 'react';
import { Box, Skeleton, Text, useColorMode } from '@chakra-ui/react';
import { Answer, Question } from '../../../types/interface';
import QuestionCard from '../../../components/Questions/QuestionCard';

interface BookmarkQuestionsProps {
  questions: Question[];
  isLoading: boolean;
  viewAnswers:  (id: string) => void;
  updatedAnswersData: (questionId: string, updatedAnswers: Answer[]) => void;
}

const BookmarkQuestions: React.FC<BookmarkQuestionsProps> = ({ questions, isLoading, viewAnswers, updatedAnswersData }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
    paddingTop="20px"
    paddingBottom="20px"
    style={{
      backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#588552',
      borderRadius: '20px',
    }}>
      {isLoading ? (
        <Box textAlign="center" p={10}>
          <Skeleton height="500px" borderRadius={20} color={"transparent"} />
        </Box>
      ) : (
        questions.length > 0 ? (
          questions.map((question) => (
            <Box key={question?._id} 
            padding={10}
            justifyContent="center"
            alignItems="start"
            flexDirection={'column'}>
              <QuestionCard
                question={question}
                onQuestionClick={viewAnswers}
                updatedAnswersData={updatedAnswersData}
              />
            </Box>
          ))
        ) : (
          <Text textAlign="center" marginTop="20px">
            No questions bookmarked yet.
          </Text>
        )
      )}
    </Box>
  );
};

export default BookmarkQuestions;
