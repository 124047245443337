import React, { useEffect, useState } from 'react';
import { Box, Text, useColorMode, HStack, VStack, Badge, Button, Image, Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AutoBackButton } from '../../components';
import { useGetStoreByUserIdQuery } from '../../api/stores';
import { Store, User, RootState } from '../../types/interface';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
// import PlanUpgrade from './PlanUpgrade';
import StoreProducts from './StoreProducts';
import { useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { selectStoreUserIds } from '../../slice/storeSlice';



const UserStores = () => {
    const { userId} = useParams(); // Get the post ID from URL params
  const { colorMode } = useColorMode();
  const currentUser = useSelector(selectCurrentUser) as User;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  // Get store data directly using the hook
  const { data: store, isLoading, isError } = useGetStoreByUserIdQuery(userId);
  const language = useSelector((state: RootState) => state.language.language); 
  const [storeData, setStoreData] = useState<Store[]>([]);
  // const [showUpgradeForm, setShowUpgradeForm] = useState(false);

  // console.log("Store:", storeData);
  useEffect(() => {
    if (store) {
      setStoreData(store.data); // Assuming store.data is an array of stores
    }
  }, [store]);

  // Function to check if the user can create an article
  const canCreateArticle = () => {
    return currentUser?.role === 'SPECIALIST';
  };

    const storeUserIds = useSelector(selectStoreUserIds);
  
    // Check if currentUser.id is in the storeUserIds
    const canCreateStore = !storeUserIds.includes(currentUser?.id);
  
  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-store`;
      navigate(createPostUrl);
    } else {
      navigate('/login'); 
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{storeData.length > 0 ? 'Store Details' : 'Store Listings'}</title>
      </Helmet>
      <AutoBackButton /> 
      <Box >
        {isLoading ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">
            Loading store details...
          </Box>
        ) : isError  ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">
            Error loading store details.
          </Box>
        ) :  storeData.length === 0 ?(

          // const isOwner = currentUser?.id === storeItem?.user?._id;
          <Box textAlign="center" fontSize="18px" mb={4} fontWeight="bold">
        <Text
         mb={4} 
         >
        {language === 'en' 
          ? "You haven't created any store" 
          : language === 'yo' 
          ? "E kò ti dá ọjà kankan ṣilẹ" 
          : "No has creado ninguna tienda"}
          </Text>
       {canCreateArticle() && (canCreateStore === false) && currentUser && (
              <Box textAlign="center">
          <Button
            onClick={redirectToCreatePost}
            mt={0}
            bg={colorMode === 'light' ? '#345430' : 'white'}
            padding="20px"
            color={colorMode === 'light' ? 'white' : '#345430'}
            variant="solid"
            borderRadius="10px"
            _hover={{
              color: '#345430',
              backgroundColor: '#F7F7F9',
            }}
            size="md"
          >
            {language === 'en' ? 'Create Store ' : language === 'yo' ? 'Sí Ọjà ' : 'Crear Tienda'}
          </Button>
        </Box>
      ) }
        </Box>
        ) : (
          storeData.map((storeItem, index) => {
            const isOwner = currentUser?.id === storeItem?.user?._id;
            // console.log("Store Item:", storeItem);
            // console.log("Is Owner:", isOwner);
            return (
              <Box
                key={index}
                padding="20px"
                // margin="10px"
                borderRadius="10px"
              >
  
                  <Flex
                  direction={{ base: 'column', md: 'row' }}
                  justify="space-between"
                  align="start"
                  gap={5}
                >
                  <Image
                    src={storeItem?.image}
                    alt={storeItem?.name}
                    borderRadius="8px"
                    width="100%"
                    height={'350px'}
                    objectFit="cover"
                    boxSize={200}
                    style={{
                      backgroundColor: colorMode === "light" ? "#E0EAE0" : "white",
                      color: colorMode === 'light' ? "white" : "#345430",
                    }}
                  />
                  <VStack align="start" spacing={4} flex="1">
                    <Text
                      fontSize={{ base: '20px', md: '30px' }}
                      fontWeight="bold"
                      color={colorMode === 'light' ? '#345430' : 'white'}
                    >
                      {storeItem?.name}
                    </Text>
                    <Text fontSize="sm" color={colorMode === 'light' ? '#345430' : 'white'}>
                      {storeItem?.description}
                    </Text>
                    <HStack wrap="wrap" spacing={2}>
                      <Text fontWeight="bold">Categories:</Text>
                      {storeItem?.category?.length ? (
                        storeItem?.category.map((cat, catIndex) => (
                          <Badge key={catIndex} colorScheme="green" borderRadius="md">
                            {cat}
                          </Badge>
                        ))
                      ) : (
                        <Text color="gray.500">No categories available</Text>
                      )}
                    </HStack>
                    <HStack>
                      <Text>
                        <strong>Status:</strong>{' '}
                        <Badge colorScheme={storeItem?.deactivated ? 'red' : 'green'}>
                          {storeItem?.deactivated ? 'Deactivated' : 'Active'}
                        </Badge>
                      </Text>
                    </HStack>
                    <HStack>
                               {isOwner && (
                      <Text>
                      <strong>Tokens Available:</strong> {storeItem?.tokenAvailable ?? 'N/A'}
                    </Text>
                  )}
                    </HStack>
                    <HStack>
                       <Text>
                        <strong>Store Phone:</strong> {storeItem?.phoneNumber ?? 'N/A'}
                      </Text>
                      </HStack>

                    {isOwner && (
                      <>
                        <Button
                          colorScheme="blue"
                          onClick={() =>
                            navigate(`/user/${currentUser?.id}/stores/${storeItem?._id}/edit-store`)
                          }
                        >
                          <FiEdit /> Edit Store
                        </Button>
                        {!storeItem?.deactivated && storeItem?.tokenAvailable > 0 && (
                          <Link
                            to={`/user/${currentUser.id}/stores/${storeItem._id}/create-products?maxProducts=${storeItem?.tokenAvailable}`}
                          >
                            <Button colorScheme="teal">Create Product</Button>
                          </Link>
                        )}
                      </>
                    )}
                    </VStack>
                </Flex>

              
                {!storeItem?.deactivated && (
                    <StoreProducts storeId={storeItem?._id} storeUserId={storeItem?.user?._id}/>
          )}
         


                {canCreateArticle() && 
                (storeItem?.deactivated || storeItem?.tokenAvailable === 0) &&
                currentUser?.id === storeItem.user?._id && (
                  <>
                    <Text color="red.500" mt={4}>
                      {storeItem.deactivated
                        ? 'Your store is deactivated. Please send an email to contact@ewe.com.ng to activate your store.'
                        : "You've exhausted your tokens. Upgrade your plan to create more products."}
                    </Text>
                    {(!storeItem?.deactivated || storeItem?.tokenAvailable === 0)
                    && currentUser?.id === storeItem.user?._id
                     &&  (
                      <Button
                      onClick={() => navigate(`${storeItem._id}/plan-upgrade`)} // Add the appropriate path
                      backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                      color={colorMode === 'light' ? 'white' : '#345430'}
                      size="lg"
                      width={'sm'}
                      marginTop={'10px'}
                    >
                      Upgrade Plan
                    </Button>
                    
                    )}
                  </>
                )}
              </Box>
            );
          })
        )}
      </Box>
      {/* {showUpgradeForm && <PlanUpgrade />} */}
    </motion.div>
  );
};

export default UserStores;
