import { useState, useRef } from 'react';
import { Button, Input, Textarea, VStack, Box, Flex, Image, Text, useColorMode, Heading } from '@chakra-ui/react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
// import { useCreateQuestionMutation } from '../api/questions';
import { useSelector } from 'react-redux';
import { RootState, User } from '../types/interface';
import { AutoBackButton, ToastNotification } from '../components';
import { selectAccessToken, selectCurrentUser } from "../slice/userSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface FormData {
  images: File[];
  question: string;
}

const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes
const MAX_FILE_COUNT = 4; // Max number of files

const CreateQuestion = () => {
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  // const [createQuestion] = useCreateQuestionMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { colorMode } = useColorMode();
  const language = useSelector((state: RootState) => state.language.language);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const accessToken = useSelector(selectAccessToken); 
  const navigate = useNavigate(); // Add useNavigate hook


  const { handleSubmit, control, register, formState: { isValid, isSubmitting }, reset } = useForm<FormData>({
    defaultValues: {
      images: [],
      question: '',
    },
    mode: 'onBlur',
  });

  const selectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const validFiles = selectedFiles
        .filter(file => file.size <= MAX_FILE_SIZE) // Filter by file size
        .slice(0, MAX_FILE_COUNT - files.length); // Ensure we don't exceed max file count

      if (validFiles.length < selectedFiles.length) {
        setToastConfig({
          description: 'Some files were too large or limit exceeded. Max file size is 1MB and max 4 images.',
          status: 'error',
        });
        setShowToast(true);
      }

      const imagePreviews = validFiles.map((file) => URL.createObjectURL(file));
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setPreviewImages((prevImages) => [...prevImages, ...imagePreviews]);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    selectFiles(e);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    console.log('onSubmit triggered', data);
    const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);
  
    if (oversizedFiles.length > 0) {
      setToastConfig({
        description: 'Some files are too large. Please upload files smaller than 1MB.',
        status: 'error',
      });
      setShowToast(true);
      return;
    }
  
    try {
      setLoading(true);
  
      const formData = new FormData();
      formData.append('lang', language);
      formData.append('question', data.question);
      files.forEach((file) => {
        formData.append('files', file);
      });
  
      // const response = await createQuestion({ userId: currentUser.id, body: formData }).unwrap();


      const response = await axios.post(
        `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/questions`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`, 
            'Access-Control-Allow-Origin': '*/*',
          },
        }
      );
  
      // // Log the response to see the entire data structure
      // console.log('Response: ', response); 
  
      // Handle success
   
      if ('data' in response) {
        // Success response
        const responseData = response.data.data;
        // console.log('Response Data: ', responseData);
        const message = responseData.message || 'Success !!! Question was successfully sent!';
        setToastConfig({ description: message, status: 'success' });
    

  
        setToastConfig({
          description: response.data.message || `${responseData.name} Question was successfully sent`,
          status: 'success',
        });
        setShowToast(true);       
        reset();
        navigate(`/user/${currentUser.id}/questions`);
      } else if ('error' in response) {
        setToastConfig({ description: 'Error in Creating  Question', status: 'error' });
        setShowToast(true);
        setLoading(false);
      }
    } catch (error: any) {
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click event of the file input element
    }
  };

  return (
    <Box padding={4}>
      <AutoBackButton/>
      <Heading fontFamily="Inter" as="h6" fontSize={{ base: '20px', md: "30px" }} textAlign="center">
        {language === 'en' ? 'Create Question' : language === 'yo' ? 'Ṣẹda ìbéèrè kan' : 'Crear Pregunta'}
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' method="POST">
        <VStack spacing={10} align="center" mt={5} mb={5}>
        <Controller
                control={control}
                name="images"
                rules={{ required: true }}
                render={({ field }) => (
                  <Flex
                    justify="center"
                    align="center"
                    flexDirection="column"
                    borderWidth="2px"
                    borderStyle="dotted"
                    borderColor="gray.300"
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderRadius={20}
                    p={5}
                    w="full"
                    h="auto"
                  >
                    {!previewImages.length ? (
                      <label>
                        <Flex flexDirection="column" alignItems="center" justifyContent="center">
                          <Box>
                            <AiOutlineCloudUpload
                              size="48px"
                              color={colorMode === 'light' ? 'white' : '#345430'}
                            />
                          </Box>
                          <Text fontSize="lg">Click to upload</Text>
                        </Flex>
                        <Text mt="32px" fontSize="sm" color="black">
                          Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF, or TIFF less than 1MB
                          Maximum of four images
                        </Text>
                        <Input
                        {...register("images")}
                        type="file"
                        id="upload-image"
                        onChange={(e) => {
                          // selectFiles(e); // Call your selectFiles function to handle file selection
                          field.onChange(e); 
                          handleFileChange(e)
                        }}
                        accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                        multiple
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      </label>
                    ) : (
                      <>
                        <Flex flexWrap="wrap">
                          {previewImages.map((imageUrl, index) => (
                            <Box key={index} position="relative" m="2">
                              <Image
                                src={imageUrl}
                                alt={`uploaded-pic-${index}`}
                                h="300px"
                                w="300px"
                                objectFit={'cover'}
                              />
                              <Button
                                type="button"
                                position="absolute"
                                top="0"
                                right="0"
                                p="1"
                                rounded="full"
                                bg="white"
                                fontSize="sm"
                                cursor="pointer"
                                outline="none"
                                _hover={{ boxShadow: 'md' }}
                                onClick={() => setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index))}
                              >
                                <MdDelete color={'red'} />
                              </Button>
                            </Box>
                          ))}
                        </Flex>
                        {previewImages.length > 0 && (
                          <>
                        <Input
                          {...register("images")}
                          type="file"
                          id="upload-image"
                          onChange={(e) => {
                            // selectFiles(e);
                            field.onChange(e); 
                            handleFileChange(e)
                          }}
                          accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                          multiple
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                        />

                            <Button
                              type="button"
                              mt="2"
                              backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                              color={colorMode === 'light' ? 'white' : '#345430'}
                              size="sm"
                              onClick={handleButtonClick}
                            >
                              Upload Another Image
                            </Button>
                          </>
                        )}
                        <Button
                          type="button"
                          mt="2"
                          backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                          color={colorMode === 'light' ? 'white' : '#345430'}
                          size="sm"
                          onClick={() => setPreviewImages([])}
                        >
                          Reset
                        </Button>
                      </>
                    )}
                  </Flex>
                )}
              />
          <Controller
            name="question"
            control={control}
            defaultValue=""
            rules={{ required: true, maxLength: 800 }}
            render={({ field }) => (
              <Textarea
                placeholder={language === 'en' ? 'Create Question' : language === 'yo' ? 'Ṣẹda ìbéèrè kan' : 'Crear Pregunta'}
                {...field}
                backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                borderColor={colorMode === 'light' ? '#345430' : 'white'}
                fontSize="20px"
                height="200px"
              />
            )}
          />

          <Button
            isLoading={loading || isSubmitting}
            type='submit'
            loadingText="Posting..."
            backgroundColor={colorMode === 'light' ? "#345430" : "white"}
            color={colorMode === 'light' ? 'white' : '#345430'}
            size="lg"
            width={'full'}
            marginTop={'10px'}
          >
            Submit
          </Button>

        </VStack>
      </form>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Box>
  );
};

export default CreateQuestion;
