import React, { useEffect, useState } from 'react';
import { useColorMode, Box, Text, Skeleton } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Articles, RootState } from '../types/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetArticlesQuery } from '../api/articles';
import { AutoBackButton, Header } from '../components';
import { motion } from 'framer-motion';
import ArticleList from '../components/Articles/ArticleList';

const Article = () => {
  const { colorMode } = useColorMode();
  const language = useSelector((state: RootState) => state.language.language);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { userId } = useParams();
  const isAuthenticated = useSelector((state: RootState) => state.users.isLogin);
  const [trigger, { data, isLoading }] = useLazyGetArticlesQuery({});
  const [articles, setArticles] = useState<Articles[]>([]);

  const handleArticleClick = (articleId: string) => {
    navigate(`/user/${userId}/articles/${articleId}`);
  };

  useEffect(() => {
    trigger({}, true); // Prefer cached value if available
  }, [trigger]);


  useEffect(() => {
    if (data) {
      const articles = data?.data?.articles;
      // console.log("articles: ", articles)
      setArticles(articles);
    }
  }, [data]);

  const updateArticleData = (newData: Partial<Articles>) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) => (article?._id === newData._id ? { ...article, ...newData } : article))
    );
  };

  // console.log("Articles:", articles);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewé - Publication Page</title>
      </Helmet>
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#345430',
          color: colorMode === 'light' ? '#345430' : 'white',
        }}
      >
        <AutoBackButton />
        <Text
          fontSize={{ base: '20px', md: '30px', lg: '40px' }}
          fontWeight="600"
          fontFamily="Inter"
          letterSpacing={3}
          textAlign="center"
          color={colorMode === 'light' ? '#345430' : 'white'}
          paddingTop="40px"
          paddingBottom="20px"
        >
        {language === 'en' ? 'Publications' : (language === 'yo' ? 'Iroyin' : 'Publicación')}
        </Text>
        <Box
          paddingTop="20px"
          paddingBottom="20px"
          marginRight={{ base: '15', md: '20' }}
          marginLeft={{ base: '15', md: '20' }}
          bg={colorMode === 'light' ? 'white' : '#345430'}
          color={colorMode === 'light' ? '#345430' : 'white'}
          fontFamily="Inter"
        >
          {isLoading ? (
            <Box>
              {[1, 2, 3].map((item) => (
                <Box
                  key={item}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  mb={4}
                  padding={10}
                  display="flex"
                  flexDirection={{ base: 'column', sm: 'row' }}
                  gap={10}
                >
                  <Skeleton height="200px" width="30%" mr={4} />
                  <Box flexBasis="70%">
                    <Skeleton height="30px" mb={2} />
                    <Skeleton height="20px" mb={2} />
                    <Skeleton height="20px" mb={2} />
                    <Skeleton height="20px" width="50%" />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <ArticleList
                articles={articles}
                isAuthenticated={isAuthenticated}
                handleArticleClick={handleArticleClick}
                updateArticleData={updateArticleData} 
                  />
          )}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default Article;