import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller, SubmitHandler, useFieldArray } from 'react-hook-form';
import { FormControl, FormLabel, Input, Button, Textarea, VStack, Box, Select, Flex, Heading, Image, useColorMode, Spinner, Center, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useEditStoreProductsMutation, useGetStoreProductDetailsQuery } from '../../api/stores';
import { User, ItemCategory } from '../../types/interface';
import { selectAccessToken, selectCurrentUser } from '../../slice/userSlice';
import axios from 'axios';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { ToastNotification , AutoBackButton} from '../../components';

interface Price {
    size: string;
    price_dollar: number;
    price_naira: number;
  }
  
  
  interface FormData {
    priceDollar: number;
    priceNaira: number;
    images: string[];
    quantity: string;
    name: string;
    description: string;
    category: ItemCategory;
    prices: Price[];
  }

const EditStoreProducts: React.FC = () => {
  const { storeId, productId } = useParams<{ storeId: string; productId: string }>();
  const currentUser = useSelector(selectCurrentUser) as User;
  const { data: product, isLoading } = useGetStoreProductDetailsQuery({ storeId, productId });
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: "", status: "" });
  const [backendImages, setBackendImages] = useState<string[]>([]); // Existing images
  const [previewImages, setPreviewImages] = useState<string[]>([]); // Previews for new files
    const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const { control, handleSubmit, register, reset, setValue } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      priceDollar: 0,
      priceNaira: 0,
      quantity: '',
      category: ItemCategory.OIL,
      prices: [{ size: '', price_dollar: 0, price_naira: 0 }],
      images: [],
    },
    criteriaMode: 'all',
    mode: 'onChange', // Trigger validation only for changed fields
  });

  const { fields: variantFields, append: appendVariant, remove: removeVariant } = useFieldArray({
    control,
    name: 'prices',
  });


  // console.log("products: ", product);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click event of the file input element
    }
  };


  const [editStoreProducts] = useEditStoreProductsMutation();
  const { colorMode } = useColorMode();
  // Fetch and set product data
  useEffect(() => {
    if (product) {
      setValue('name',  product?.data?.name);
      setValue('description', product?.data?.description);
      setValue('priceDollar', product?.data?.dollar_price);
      setValue('priceNaira', product?.data?.naira_price);
      setValue('quantity', product?.data?.quantity);
      setValue('category', product?.data?.category);
      setValue('prices', product?.data?.prices);
      setBackendImages(product?.data?.images || []);
      setValue('images', product?.data?.images || []);
    }
  }, [product, setValue]);

  // Handle image file upload
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files);
      const fileUrls: string[] = [];

      setLoading(true);
      setToastConfig({ description: 'Uploading images...', status: 'info' });
      setShowToast(true);

      for (const file of filesArray) {
        if (file.type.startsWith('image/')) {
          const formData = new FormData();
          formData.append('files', file);

          try {
            const response = await axios.post(
              `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            if (response.data?.data) {
              fileUrls.push(response.data.data);
            }
          } catch (error) {
            console.error('Image upload error:', error);
            setToastConfig({ description: 'Upload failed.', status: 'error' });
            setShowToast(true);
          }
        }
      }

      if (fileUrls.length > 0) {
    // Merge newly uploaded URLs with existing ones
        const updatedImages = [...backendImages, ...previewImages, ...fileUrls];
        setPreviewImages((prev) => [...prev, ...fileUrls]);
        setValue('images', updatedImages);
        setToastConfig({ description: 'Images uploaded successfully.', status: 'success' });
        setShowToast(true);
      }
      setLoading(false);
    }
  };


  // Handle form submission
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const payload = {
        naira_price: data.priceNaira,
        dollar_price: data.priceDollar,
        quantity: data.quantity,
        name: data.name,
        description: data.description,
        category: data.category,
        images: data?.images,
        prices: data.prices.map((price) => ({
          size: price.size,
          price_dollar: price.price_dollar,
          price_naira: price.price_naira,
        })),
      };

      const response = await editStoreProducts({ storeId, body: payload, productId }).unwrap();

      // console.log("Response: ", response);
      
      if ('data' in response) {
        setToastConfig({ description: 'Product updated successfully!', status: 'success' });
        setShowToast(true);
        reset();
        navigate(`/user/${currentUser.id}/stores/${storeId}/products/${productId}`);
      } else {
        setToastConfig({ description: 'An error occurred.', status: 'error' });
        setShowToast(true);
      }
    } catch (error: any) {
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
    }
  };

  // Loading state handling
  if (isLoading && loading) return (
    <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
      <Center h="100vh" flexDirection="column">
        <Spinner size="lg" color="teal.500" mb={4} />
        <Text fontSize="xl" color="teal.600">Loading...</Text>
      </Center>
    </Box>
  );

   // Remove images (either from backend or new uploads)
 const handleImageRemove = (index: number, fromBackend: boolean) => {
  if (fromBackend) {
    const updatedBackendImages = [...backendImages];
    updatedBackendImages.splice(index, 1);
    setBackendImages(updatedBackendImages);
    setValue('images', [...updatedBackendImages, ...previewImages]);
  } else {
    const updatedPreviews = [...previewImages];
    updatedPreviews.splice(index, 1);
    setPreviewImages(updatedPreviews);
    setValue('images', [...backendImages, ...updatedPreviews]);
  }
};
  
const resetFileState = () => {
  setPreviewImages([]);
  setValue('images', backendImages);
};

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5, ease: 'easeIn' }}
    style={{
      backgroundColor: colorMode === 'light' ? 'white' : '#345430',
      color: colorMode === 'light' ? '#345430' : 'white',
    }}
  >
    <AutoBackButton />
    <motion.div
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        paddingBottom: '20px',
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
    >
      <Box
        fontFamily="Inter"
        paddingTop={20}
        marginRight={{ base: '15', md: '20' }}
        marginLeft={{ base: '15', md: '20' }}
        pb={20}
      >
        <Heading
          fontFamily="Inter"
          as="h6"
          fontSize={{ base: '20px', md: '30px' }}
          textAlign="center"
        >
          Edit Product
        </Heading>
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={10} align="center" mt={5} mb={5}>
        {/* Image Upload */}
        <Controller
                      control={control}
                      name="images"
                      render={({ field }) => (
                        <Flex
                          justify="center"
                          align="center"
                          flexDirection="column"
                          borderWidth="2px"
                          borderStyle="dotted"
                          borderColor="gray.300"
                          backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                          borderRadius={20}
                          p={5}
                          w="full"
                          h="auto"
                        >
                          {!previewImages.length && !product?.data?.images?.length ? (
                            <label>
                              <Flex flexDirection="column" alignItems="center" justifyContent="center">
                                <Box>
                                  <AiOutlineCloudUpload
                                    size="48px"
                                    color={colorMode === 'light' ? 'white' : '#345430'}
                                  />
                                </Box>
                                <Text fontSize="lg">Click to upload</Text>
                              </Flex>
                              <Text mt="32px" fontSize="sm" color="black">
                                Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF, or TIFF less than 1MB.
                                Maximum of four images.
                              </Text>
                              <Input
                                  {...register("images")}
                                  type="file"
                                  id="upload-image"
                                  onChange={(e) => {
                                    field.onChange(e); 
                                    handleFileUpload(e); 
                                  }}
                                  accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                                  multiple
                                  style={{ display: 'none' }}
                                  // disabled={fileLimit}
                                  ref={fileInputRef}
                                />
                            </label>
                          ) : (
                            <>
                              {/* Show existing images (from backend) and new previews */}
                              <Flex wrap="wrap" gap="10px">
                                {/* Render Backend Images */}
                                {backendImages.map((image, index) => (
                                  <Box key={`backend-${index}`} position="relative">
                                    <Image src={image} alt={`Backend ${index}`} h="300px" w="300px" objectFit="cover" />
                                    <Button
                                      position="absolute"
                                      top="5px"
                                      right="5px"
                                      size="sm"
                                      onClick={() => handleImageRemove(index, true)} // Indicate backend
                                      colorScheme="red"
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                ))}

                                {/* Render Previews of New Files */}
                                {previewImages.map((preview, index) => (
                                  <Box key={`preview-${index}`} position="relative">
                                    <Image src={preview} alt={`Preview ${index}`} h="300px" w="300px" objectFit="cover" />
                                    <Button
                                      position="absolute"
                                      top="5px"
                                      right="5px"
                                      size="sm"
                                      onClick={() => handleImageRemove(index, false)} // Indicate new file
                                      colorScheme="red"
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                ))}
                              </Flex>
                       {(previewImages.length > 0 || backendImages.length > 0) && (
                          <>
                        <Input
                          {...register("images")}
                          type="file"
                          id="upload-image"
                          onChange={(e) => {
                            field.onChange(e); 
                            handleFileUpload(e); // Handle file upload API if needed
                          }}
                          accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                          multiple
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                        />

                            <Button
                              type="button"
                              mt="2"
                              backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                              color={colorMode === 'light' ? 'white' : '#345430'}
                              size="sm"
                              onClick={handleButtonClick}
                            >
                              Upload Another Image
                            </Button>
                          </>
                        )}
                              <Button
                                type="button"
                                mt="2"
                                backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                                color={colorMode === 'light' ? 'white' : '#345430'}
                                size="sm"
                                onClick={() => resetFileState()}
                              >
                                Reset
                              </Button>
                            </>
                          )}
                        </Flex>
                      )}
                    />

        {/* Product Information */}
        <FormControl isRequired>
          <FormLabel htmlFor="name">Product Name</FormLabel>
          <Input id="name" {...register('name', { required: 'This field is required' })} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="description">Product Description</FormLabel>
          <Textarea id="description" h={350} {...register('description', { required: 'This field is required' })} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="priceNaira">Price in Naira</FormLabel>
          <Input id="priceNaira" type="number" {...register('priceNaira', { required: 'This field is required' })} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="priceDollar">Price in Dollar</FormLabel>
          <Input id="priceDollar" type="number" {...register('priceDollar', { required: 'This field is required' })} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="quantity">Quantity</FormLabel>
          <Input id="quantity" {...register('quantity', { required: 'This field is required' })} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="category">Category</FormLabel>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select {...field}>
                {Object.entries(ItemCategory).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
            )}
          />
        </FormControl>

        {/* Pricing variants */}
        <Box w="full">
          <Heading as="h3" size="md">Pricing Variants</Heading>
          {variantFields.map((field, index) => (
            <Box key={field.id} borderWidth="1px" p={4} my={2}>
              <FormControl>
                <FormLabel htmlFor={`prices.${index}.size`}>Size</FormLabel>
                <Input {...register(`prices.${index}.size`)} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`prices.${index}.price_naira`}>Price Naira</FormLabel>
                <Input {...register(`prices.${index}.price_naira`)} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`prices.${index}.price_dollar`}>Price Dollar</FormLabel>
                <Input {...register(`prices.${index}.price_dollar`)} />
              </FormControl>
              <Button
                colorScheme="red"
                onClick={() => removeVariant(index)}
                pt={2}
              >
                Remove
              </Button>
            </Box>
          ))}
          <Button     
                backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                color={colorMode === 'light' ? 'white' : '#345430'}
                size="sm"
                onClick={() => appendVariant({ size: '', price_naira: 0, price_dollar: 0 })}>
            Add Variant
          </Button>
        </Box>

        {/* Submit */}
        <Button 
                  loadingText="Editing Product..."
                  backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                  color={colorMode === 'light' ? 'white' : '#345430'}
                  size="sm"
                  type="submit">
          Save Product
        </Button>
      </VStack>
      {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} />}
    </form>
    </Box>
      </motion.div>
    </motion.div>
  );
};

export default EditStoreProducts;
