import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Flex, useColorMode } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { PostContent, SearchInput, SortComponent, FilterComponent, AutoBackButton } from '../components';
import { filterOptions } from '../data/filterType';
import { selectCurrentUser } from '../slice/userSlice';
import { Post, RootState, User } from '../types/interface';
import { useSelector } from 'react-redux';
import { useLazyGetAllPostsQuery } from '../api/postApi';

const Posts = () => {
  const [trigger, { data: posts, isLoading: postsLoading, isError: postsError }] = useLazyGetAllPostsQuery();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [allPosts, setAllPosts] = useState<Post[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedSort, setSelectedSort] = useState('');
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const isAuthenticated = useSelector((state: RootState) => state.users.isLogin);

  useEffect(() => {
    trigger({}, true); // Prefer cached value if available
  }, [trigger]);

  useEffect(() => {
    if (posts) {
      const allPost = posts?.data?.posts;
      setAllPosts(allPost);
      setFilteredPosts(allPost); // Display all posts initially
    }
  }, [posts]);

  const normalizeString = (str: string) => {
    if (str) {
      return str
        .normalize('NFD') // Decompose combined characters into base + diacritics
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
        .toLowerCase(); // Convert to lowercase for case-insensitive comparison
    }
    return "";
  };

  const inHouseSearch = (data: any[], query: string) => {
    const normalizedQuery = normalizeString(query);
    return data.filter(item => {
      const normalizedName = normalizeString(item?.name || "");
      const normalizedTitle = normalizeString(item?.title || "");
      return normalizedName.includes(normalizedQuery) || normalizedTitle.includes(normalizedQuery);
    });
  };


  useEffect(() => {
    // Apply filtering if a filter is selected
    if (selectedFilter !== '') {
      const filtered = allPosts.filter((post: any) => post.type === selectedFilter);
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(allPosts); // Reset filteredPosts to all posts if no filter is applied
    }
  }, [selectedFilter, allPosts]);

  const filteredAndSortedPosts = useMemo(() => {
    let filteredPostsCopy = [...filteredPosts];
    
      // Apply search query filtering with normalization
      if (searchQuery) {
        filteredPostsCopy = inHouseSearch(filteredPostsCopy, searchQuery);
      }
  

    // Apply filtering
    if (selectedFilter !== '') {
      filteredPostsCopy = filteredPostsCopy.filter((post) => post.type === selectedFilter);
    }
  
    // Apply sorting
    switch (selectedSort) {
      case 'A-Z':
        filteredPostsCopy.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'Latest':
        filteredPostsCopy.sort((a, b) => parseInt(b._id) - parseInt(a._id));
        break;
      case 'verified':
        filteredPostsCopy = filteredPostsCopy.filter((post) => post.verify === true);
        break;
      case 'unVerified':
        filteredPostsCopy = filteredPostsCopy.filter((post) => post.verify === false);
        break;
      default:
        break;
    }
  
    return filteredPostsCopy;
  }, [searchQuery, selectedFilter, selectedSort, filteredPosts]);
  

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    try {
      setSearchQuery(query);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePostData = (postId: string, newData: any) => {
    setAllPosts((prevPosts: any[]) =>
      prevPosts.map((post: any) =>
        post._id === postId ? { ...post, ...newData } : post
      )
    );
  };

  const handlePostCardClick = (postId: string) => {
    navigate(`/user/${userId}/posts/${postId}`);
  };


  const handleFilterChange = useCallback((type: string) => {
    setSelectedFilter(type); // Set selected filter
  }, []);

  const handleSortChange = (sortOption: any) => {
    setSelectedSort(sortOption); // Set selected sort option
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewé - Post Page</title>
      </Helmet>
      <Box
        paddingTop="20px"
        paddingBottom="20px"
        marginRight={{ base: '15', md: '20' }}
        marginLeft={{ base: '15', md: '20' }}
      >
      <AutoBackButton/>
        <Box
          padding={4}
          display={'flex'}
          flexDirection={{ base: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          gap={10}
        >
          <SearchInput
            backgroundColor="#E0EAE0"
            query={searchQuery}
            updateQuery={(query) => handleSearch(query)}
            isLoading={loading}
            onSubmit={handleSearch}
          />

          <Flex
            display={'flex'}
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ base: 'row', md: 'row' }}
            gap={'10'}
          >
            <FilterComponent
              onFilterChange={handleFilterChange}
              filterOptions={filterOptions}
            />
            <SortComponent onSortChange={handleSortChange} />
          </Flex>
        </Box>
        {postsError ? (
  <Box textAlign="center" fontSize="18px" fontWeight="bold">
    Error loading posts. Please try again.
  </Box>
) : (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, ease: 'easeIn' }}
    style={{ background: colorMode === 'light' ? '#E0EAE0' : '#588552' }}
  >
    <div style={{ overflowY: 'auto' }}>
      <Box
        padding="20px"
        style={{
          backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#588552',
          borderRadius: '20px',
        }}
      >
        {postsLoading ? ( // Check if posts are still loading
          <Box textAlign="center" fontSize="18px" fontWeight="bold">
            Loading...
          </Box>
        ) : filteredAndSortedPosts.length === 0 ? ( // Check if there are no posts
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Box textAlign="center" fontSize="18px" fontWeight="bold">
              No posts found
            </Box>
          </motion.div>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="start"
            flexDirection={'column'}
          >
            {filteredAndSortedPosts.map((post: any) => (
              <PostContent
                key={post._id}
                post={post}
                handlePostCardClick={handlePostCardClick}
                currentUser={currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={postsLoading}
                updatedPostData={updatePostData}
              />
            ))}
          </Flex>
        )}
      </Box>
    </div>
  </motion.div>
)}

      </Box>
    </motion.div>
  );
};

export default Posts;
