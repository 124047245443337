import logo from "../assets/ifaniwura.jpg";
// import plant from '../assets/leaf_icon.png'
// import tree from '../assets/verified_icon.png'
// import goggle from '../assets/google.png'
// import facebook from '../assets/facebook.png'
// import awujo from './awujo.jpeg'
// import aico from '../assets/aico.jpeg'
import tyf from './TYF.jpg'
import ifamosun from './ifamosun.jpeg'
import ruwa from '../assets/ruwa.jpeg'
// companyLogos.js
const companyLogos = [
  { path: logo, index: 0, url: "/" },
  { path: tyf, index: 2, url: "https://thinkyorubafirst.org" },
  // { path: awujo, index: 1, url: "https://awujo.org" },
  // { path: aico, index: 1, url: "https://aicomg.org" },
  { path: ruwa, index: 3, url: "https://ruwa.ng" },
  { path: ifamosun, index: 4, url: "https://main.temploifamosun.com" },
];

export default companyLogos;
