import React, { useState } from 'react';
import { Box, Button, Container, Text, useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderText from './HeaderText';
import SearchInput from '../Search/SearchInput';
import bg from '../../assets/Background_Transparent.png';
import { RootState, User } from '../../types/interface';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';

const Header = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  const language = useSelector((state: RootState) => state.language.language); 
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User;  
  const { colorMode } = useColorMode();

  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-post`;
      navigate(createPostUrl);
    } else {
      navigate('/login'); 
    }
  };

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    try {
      setSearchQuery(query);
      navigate(`/search?query=${query}`); // Navigate to the search results page with the query as a URL parameter
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const text = {
    title: {
      yo: 'Ẹkáàbọ̀ si Ewé',
      en: 'Welcome to Ewé',
      es: 'Bienvenido a Ewé',
    },
    description: {
      yo: 'Ewé jẹ́ àkójọpọ̀ ìmọ̀ ìjìnlẹ̀ nipa gbogbo ohun tí ọ ní ṣe pẹlú ayé abálayé lati awọ́n òhun ìgbìn, igi, ẹranko, kòkòrò, titi kan agbo, odù ifá ati òògùn ibilẹ nilé Yorùbá',
      en: 'Welcome to Ewé\nGathering Yorùbá ecological knowledge on plants, animals, insects, trees and herbs.',
      es: 'Bienvenido a Ewé\nReuniendo el conocimiento ecológico yorùbá sobre plantas, animales, insectos, árboles y hierbas.',
    },
  };

  return (
    <Box
      bg={colorMode === 'light' ? 'white' : '#345430'}
      backgroundImage={bg}
      backgroundSize="cover"
      backgroundPosition="center"
      color={colorMode === 'light' ? '#345430' : 'white'}
      height="70vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container>
        <HeaderText text={text} />
        <SearchInput
          backgroundColor="#E0EAE0"
          query={searchQuery}
          updateQuery={handleSearch}
          isLoading={isLoading}
          onSubmit={handleSearch}
        />
        {/* Uncomment the line below to display a loader when loading */}
        {/* {isLoading && <Loader />} */}
        <Text
          fontFamily="Inter"
          textAlign="center"
          color={colorMode === 'light' ? '#345430' : 'white'}
          fontSize={18}
          paddingTop={5}
          paddingBottom={5}
        >
          {language === 'en' ? 'Join other users and add a new discovery' : language === 'yo' ? 'Ẹ darapọ̀ mọ́ àwọn olùmúlò wa, kí ẹ ṣe àwárí tun-tun mìíràn ' : 'Únete a otros usuarios y agrega un nuevo descubrimiento'}
        </Text>
        <Box textAlign="center">
          <Button
            onClick={redirectToCreatePost}
            mt={0}
            bg={colorMode === 'light' ? '#345430' : 'white'}
            padding="20px"
            color={colorMode === 'light' ? 'white' : '#345430'}
            variant="solid"
            borderRadius="10px"
            _hover={{
              color: '#345430',
              backgroundColor: '#F7F7F9',
            }}
            size="md"
          >
            {language === 'en' ? 'Create Post' : language === 'yo' ? 'Fún wa ní ìwádí kan' : 'Crear Publicación'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
