import { useState, useRef } from "react";
import {
  Input,
  Button,
  Textarea,
  Checkbox,
  CheckboxGroup,
  VStack,
  Box,
  Text,
  useColorMode,
  Heading,
  Wrap,
  WrapItem,
  FormLabel,
  Image,
  Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastNotification, FormPhoneInput } from "../../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { selectAccessToken, selectCurrentUser } from "../../slice/userSlice";
import { addStore } from "../../slice/storeSlice";
import { RootState, User } from "../../types/interface";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

enum ItemCategory {
  OIL = "Oils",
  SOAP = "Soaps",
  HERBAL_MEDICINE = "Herbal Medicines",
  SPICE = "Spices",
  HERBS = "Herbs",
  SEED = "Seeds",
  SPIRITUAL_ITEMS = "Spiritual Items",
  HERBAL_TEA = "Herbal teas",
  CANDLES = "Candles",
  BEADS = "Beads",
}

interface StoreData {
  name: string;
  description: string;
  categories: ItemCategory[];
  image: string;
  phoneNumber: string;
}

const CreateStores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const accessToken = useSelector(selectAccessToken);
  const { 
    control, 
    handleSubmit, 
    formState: { isSubmitting, errors }, 
    reset,  
    trigger,  
    setValue ,
    register,}
   = useForm<StoreData>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: '', status: '' });
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const [fileUrls, setFileUrls] = useState<string>('');
  // const [selectedFiles, setSelectedFiles] = useState<File>();
  const [previewImages, setPreviewImages] = useState<string>('');

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
  
    if (files && files.length > 0) {
      const file = files[0];
  
      const formData = new FormData();
      formData.append('file', file);
  
      setLoading(true);
  
      try {
        const response = await axios.post(
          `https://ewe-api.herokuapp.com/api/files/image`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${accessToken}`,
              'Access-Control-Allow-Origin': '*/*',
            },
          }
        );
  
        if (response?.data?.data) {
          const fileUrl = response.data.data;
          setValue('image', fileUrl); // Store URL in form data
          setFileUrls(fileUrl);
          setPreviewImages(fileUrl);
          setToastConfig({
            description: 'Success! Image uploaded successfully.',
            status: 'success',
          });
          setShowToast(true);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          setToastConfig({
            description: 'Invalid file type. Ensure it is JPG, PNG, or WEBP.',
            status: 'error',
          });
        } else {
          setToastConfig({
            description: 'An error occurred during the upload.',
            status: 'error',
          });
        }
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    }
  };
  
  // console.log("Image Uploaded Url: ", fileUrls)


  const onSubmit = async (data: StoreData) => {
    setLoading(true);

    const body = {
      name : data.name,
      description: data.description,
      category : data.categories,
      image: fileUrls,
      phoneNumber: data.phoneNumber,
    }

    try {
      const response = await axios.post(
        `https://ewe-api.herokuapp.com/api/stores`,
        body,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      // console.log("Response for Store : ", response);

      const responseData = response.data;
      setToastConfig({ description: 'Store successfully created!', status: 'success' });
      dispatch(addStore(responseData));
      setShowToast(true);
      reset();
      navigate(`/user/${currentUser.id}/stores`);
    } catch (error) {
      setToastConfig({ description: 'Failed to create store', status: 'error' });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <motion.div
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#345430',
          paddingBottom: '20px',
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
      >
        <Box
          fontFamily="Inter"
          paddingTop={20}
          mx={{ base: 4, md: 10 }}
          pb={10}
        >
          <Heading
            fontFamily="Inter"
            as="h1"
            fontSize={{ base: '24px', md: '32px' }}
            textAlign="center"
            mb={6}
          >
            {language === 'en' ? 'Create a new Store ' : language === 'yo' ? 'Sí Ọjà Tuntun ' : 'Crear Tienda'}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" method="POST">
            <VStack spacing={6} align="stretch">
            <Controller
      control={control}
      name="image"
      render={({ field }) => (
        <Flex
          justify="center"
          align="center"
          flexDirection="column"
          borderWidth="2px"
          borderStyle="dotted"
          borderColor="gray.300"
          backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
          borderRadius={20}
          p={5}
          w="full"
          h="auto"
          {...field}
        >
          <FormLabel htmlFor="file-upload" display="none">Upload Image</FormLabel>
          <Input
            id="file-upload"
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload} 
            accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff, image/jpg"
          />
          <Button
            fontFamily="Inter"
            colorScheme="teal"
            borderRadius={20}
            p={5}
            onClick={() => fileInputRef.current?.click()}
          >
            <AiOutlineCloudUpload
              size="48px"
              color={colorMode === 'light' ? 'white' : '#345430'}
              style={{ marginRight: '5px' }}
            />
            Upload Image
          </Button>
          {previewImages && (
          <Box
            width="full"
            height="full"
            borderRadius="lg"
            overflow="hidden"
            position="relative"
            mt={4}
          >
            <Image
              src={previewImages}
              alt="Preview"
              h="300px"
              w="300px"
              objectFit="cover"
              borderRadius="lg"
            />
            <Button
              size="xs"
              position="absolute"
              top="0"
              right="0"
              colorScheme="red"
              onClick={() => setPreviewImages('')} // Clear preview
            >
              <MdDelete />
            </Button>
          </Box>
        )}
            {loading && <p>Loading...</p>}
        </Flex>
      )}
    />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Store Name"
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.300"
                backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                required
              />
            )}
          />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Store Description"
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor="gray.300"
                  height={500}
                  backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  required
                />
              )}
            />
              <Controller
                name="categories"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Text fontSize="lg" mb={2}>Select Store Categories</Text>
                    <CheckboxGroup {...field} onChange={field.onChange}>
                      <Wrap spacing={4}>
                        {Object.values(ItemCategory).map((category) => (
                          <WrapItem key={category}>
                            <Checkbox value={category}>{category}</Checkbox>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </CheckboxGroup>
                  </Box>
                )}
              />
   
                          <FormPhoneInput
                          name="phoneNumber"
                          label="Store Phone Number"
                          control={control}
                          register={register}
                          rules={{
                              pattern: {
                                  value: /^[0-9+\s()-]*$/,
                                  message: 'Invalid phone number',
                              },
                          }} 
                          errors={errors}       
                          onKeyUp={() => {
                            trigger("phoneNumber");
                         }}
                            />
      
              <Button
                type="submit"
                isLoading={loading || isSubmitting}
                backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                color={colorMode === 'light' ? 'white' : '#345430'}
                size="lg"
                width="full"
              >
                Submit
              </Button>
              {showToast && (
                <ToastNotification 
                description={toastConfig.description} 
                status={toastConfig.status} 
                onClose={() => setShowToast(false)} />
              )}
            </VStack>
          </form>
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default CreateStores;
