// UserQuestions.tsx
import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Skeleton, useColorMode, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import QuestionCard from "../../components/Questions/QuestionCard";
import { Question, Answer,  RootState, User } from "../../types/interface";
import { selectCurrentUser } from "../../slice/userSlice";
import { useLazyGetUserQuestionQuery } from "../../api/questions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface UserQuestionsProps {
  userId: string;
}

const UserQuestions: React.FC<UserQuestionsProps> = ({
  userId
}) => {
  const { colorMode } = useColorMode();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [triggerUserQuestions, { data: questionsData, isLoading: questionsLoading }] = useLazyGetUserQuestionQuery();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  useEffect(() => {
    if (userId) {
      triggerUserQuestions(userId);
    }
  }, [userId, triggerUserQuestions]);

  const isAuthenticated = useSelector(
    (state: RootState) => state.users.isLogin
  );
  const language = useSelector((state: RootState) => state.language.language);
  useEffect(() => {
    if (questionsData) {
      const sortedQuestions = [...questionsData.data].sort(
        (a: Question, b: Question) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setQuestions(sortedQuestions || []); 
    }// Update questions state
  }, [questionsData]);

  const handleQuestionClick = (questionId: string) => {
    navigate(`/user/${currentUser.id}/questions/${questionId}`);
  };

   // Update the answers for a specific question
   const updatedAnswersData = (questionId: string, updatedAnswers: Answer[]) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question._id === questionId
          ? { ...question, answers: updatedAnswers }
          : question
      )
    );
  };

  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-post`;
      navigate(createPostUrl);
    } else {
      navigate("/login");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
    //   style={{
    //     backgroundColor: colorMode === "light" ? "white" : "#1A202C",
    //   }}
    >
      <Box p={{ base: 4, md: 8 }}>
        <Flex direction="column" gap={6}>
          {questionsLoading ? (
            <Skeleton height="500px" borderRadius="10px" />
          ) : questions.length > 0 ? (
            questions.map((question) => (
              <QuestionCard
                key={question._id}
                question={question}
                onQuestionClick={handleQuestionClick}
                updatedAnswersData={updatedAnswersData}
              />
            ))
          ) : (
            <Box>
                  <Text
              fontSize="xl"
              fontWeight="bold"
              color={colorMode === "light" ? "gray.700" : "gray.300"}
              textAlign="center"
            >
              No questions to display
            </Text>
            {currentUser.id === userId && (
              <Button
                onClick={redirectToCreatePost}
                mt={0}
                bg={colorMode === "light" ? "#345430" : "white"}
                padding={"20px"}
                color={colorMode === "light" ? "white" : "#345430"}
                variant="solid"
                borderRadius={"10px"}
                _hover={{
                  color: "#345430",
                  backgroundColor: "#F7F7F9",
                }}
                size="md"
              >
                {language === "en"
                ? "Create Question"
                : language === "yo"
                ? "Fún wa ní ìwádí kan"
                : "Crear Pregunta"}

              </Button>
              )}
            </Box>
        
          )}
        </Flex>
      </Box>
    </motion.div>
  );
};

export default UserQuestions;
